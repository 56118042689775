import { useTheme } from "@emotion/react";
import { Box, Dialog, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { createContext, useContext, useState } from "react";

const ImagePopupContext = createContext();

const ImagePopupContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const openPopup = (url) => {
    setOpen(true);
    setUrl(url);
  };

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <ImagePopupContext.Provider value={{ openPopup, closePopup }}>
      {children}
      <Dialog
        open={open}
        onClose={closePopup} 
        fullScreen={isMobile}
        PaperProps={{ sx: { backgroundColor: "black" }}}
      >
        <Box 
          component="img" 
          src={url}
          alt="popup"
          sx={isMobile ? {
            width: "100vw",
            height: "auto",
            marginTop: "auto",
            marginBottom: "auto",
          } : {
            width: "100%",
            height: "auto",
          }}
        />
        <IconButton
          sx={{
            color: "white",
            mixBlendMode: "exclusion",
            position: "absolute",
            top: 10,
            right: 10,
          }}
          onClick={closePopup}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </ImagePopupContext.Provider>
  );
};

export const useImagePopupContext = () => {
  const context = useContext(ImagePopupContext);
  if (context === undefined)
    throw new Error("ImagePopupContext must be used within it's provider");
  return context;
};

export default ImagePopupContextProvider;
