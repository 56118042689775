import React, { useEffect, useState } from "react";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { useIdentificationService } from "../../../hooks/useIdentificationService";
import {
  eIdentAuthStateStorageKey,
  eIdentDataStorageKey,
} from "../../../util/eident";
import { useLoadingContext } from "../../../contexts/LoadingContext";
import VerticalContentGrid from "../../common/VerticalContentGrid";

export default function Identification() {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const identificationService = useIdentificationService();
  const loadingContext = useLoadingContext();

  useEffect(() => {
    loadingContext.addLoading("identify");
    const urlParams = new URLSearchParams(window.location.search);
    const state = sessionStorage.getItem(eIdentAuthStateStorageKey);
    if (
      urlParams.has("code") &&
      urlParams.has("state") &&
      urlParams.get("state") === state
    ) {
      handleIdentification(urlParams.get("code"));
    } else {
      setErrorMessage("Virhe tunnistautumisessa");
    }

    return () => {
      loadingContext.removeLoading("identify");
    };
  }, []);

  const handleIdentification = async (code) => {
    try {
      const eidentConfig = await identificationService.getConfig();
      const eidentData = await identificationService.identify({
        code,
        redirect_uri: eidentConfig.RedirectUri,
      });
      sessionStorage.setItem(eIdentDataStorageKey, eidentData);
      navigate("/sign-up");
    } catch (e) {
      setErrorMessage("Virhe tunnistautumisessa");
    }
  };

  return errorMessage ? (
    <VerticalContentGrid>
      <MuiAlert variant="filled" severity="error">
        {errorMessage}
      </MuiAlert>
    </VerticalContentGrid>
  ) : null;
}
