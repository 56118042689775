import { useMemo } from "react";
import axios from "axios";
import { useApiErrorContext, wrapWithErrorReporting } from "../contexts/ApiErrorContext";

export const useInvitationService = () => {
  const basePath = "/api/invitations";
  const apiErrorContext = useApiErrorContext();

  return useMemo(() => wrapWithErrorReporting(
    {
      find: async code => (await axios.get(`${basePath}/${encodeURIComponent(code)}`))?.data,
    },
    apiErrorContext.addError,
  ), [apiErrorContext.addError]);
};

