import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { Box, Dialog, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Style } from "../../config.js";
import { useTransactionService } from "../../hooks/useTransactionService.js";
import { formatPrice, getActionName } from "../../util/transaction.js";
import ProductStatusCard from "../common/ProductStatusCard.js";
import SortableFilterableTable from "../common/SortableFilterableTable.js";


const columnsStyle = {
  //"transaction": { width: "130px" } ,
  //"price": { align: "right" },
  "role": { textAlign: "center" },
  "indicator": { textAlign: "right", padding: "0px" },
  //"action": { textAlign: "left" },
};

const columns = [
  { key: "transaction", label: "Tuote", filterable: false, style: columnsStyle.transaction },
  { key: "payment_method", label: "Kauppatyyppi", filterable: false, style: columnsStyle.payment_method },
  { key: "price", label: "Summa", hideOnMobile: true, filterable: false, style: columnsStyle.price },
  { key: "role", label: "Rooli", hideOnMobile: true, filterable: true, style: columnsStyle.role },
  { key: "status", label: "Status", hideOnMobile: true, filterable: false },
  { key: "action", label: "Toiminta", filterable: false },
  { key: "indicator", label: "", hideOnDesktop: true, filterable: false, style: columnsStyle.indicator },
];

export default function TransactionsList({
  showColumns = ["transaction", "payment_method", "price", "role", "status", "action", "indicator"],
  filterTransactions = (tx) => true,
  header = null,
  currentTransactionId,
  setCurrentTransactionId,
}) {
  const [rows, setRows] = useState([]);
  const transactionService = useTransactionService();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const fetchTransactions = useCallback(async () => {
    const response = await transactionService.get();
    setRows(response.map((transaction) => ({
      id: String(transaction.id),
      onClick: () => setCurrentTransactionId(transaction.id),
      data: {
        transaction: transaction.name,
        payment_method: transaction.payment_method,
        price: transaction.price,
        currency: transaction.currency,
        role: transaction.role,
        status: transaction.status,
        created: transaction.created,
        action: getActionName(transaction.status, transaction.role, transaction.receiver_user_id),
        indicator: <KeyboardArrowRightIcon color="primary" fontSize="small" />,
      },
      render: {
        price: (price, row) => formatPrice(price, row.currency),
        action: (action) => action ?
          (Array.isArray(action) && action.length > 1 ?
            action.map((ac, index) => (
              <Fragment key={`ac-${index}`}>
                <Button
                  key={index}
                  color="button"
                  sx={{
                    textAlign: "left",
                    fontSize: "12px",
                    ...(index === 1 && { fontSize: "11px" })
                  }}
                  startIcon={index === 1 ? <ErrorOutlineIcon color="error" /> :
                    (index === 0 && isMobile ? <ThumbUpAltIcon color="success" /> : null)}
                  onClick={async (event) => {
                    event.stopPropagation();
                    await transactionService.statusChangeAction(transaction, index);
                    await fetchTransactions();
                  }}
                >
                  {ac}
                </Button><br />
              </Fragment>
            )) : (
              <Button
                color="button"
                sx={{ textAlign: "left" }}
                onClick={async (event) => {
                  event.stopPropagation();
                  await transactionService.statusChangeAction(transaction);
                  await fetchTransactions();
                }}
              >
                {action}
              </Button>
            )
          ) : null
      }
    })));
  }, [transactionService]);

  const updateStatus = useCallback(async (transaction, index = null) => {
    await transactionService.statusChangeAction(transaction, index);
    await fetchTransactions();
  }, [transactionService, fetchTransactions]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  return (
    <Box>
      {header}
      <SortableFilterableTable
        columns={columns.filter(column => showColumns.includes(column.key))}
        rows={rows.filter(row => filterTransactions(row.data))}
        defaultSortBy="created"
        defaultSortDirection="asc"
      />
      <Dialog
        fullScreen={isMobile}
        open={currentTransactionId !== null}
        onClose={() => setCurrentTransactionId(null)}
        PaperProps={{ sx: { borderRadius: Style.BorderRadius.Large } }}
      >
        <ProductStatusCard
          transactionId={currentTransactionId}
          handleClose={() => setCurrentTransactionId(null)}
          updateStatus={updateStatus}
          reload={fetchTransactions}
        />
      </Dialog>
    </Box>
  );
}
