/** E-Ident OpenID connect authorization request scopes */
const AuthScope = {
  OpenID: "openid",
  Cert: "cert",
  Email: "email",
  Organization: "organisation",
  Phone: "phone",
  Profile: "profile",
  SSN: "ssn",
};

export const getAuthParams = (state, config) => ({
  scope: [AuthScope.OpenID, AuthScope.Profile, AuthScope.SSN].join(" "),
  response_type: "code",
  client_id: config.ClientId,
  redirect_uri: config.RedirectUri,
  state,
});

export const getEIdentAuthURL = (state, config) =>
  `${config.AuthUri}?${new URLSearchParams(getAuthParams(state, config)).toString()}`;

export const eIdentAuthStateStorageKey = "eIdentAuthState";

export const eIdentDataStorageKey = "eIdentData";

export const invitationDataStorageKey = "invitationData";
