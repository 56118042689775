import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MuiAlert from "@mui/material/Alert";
import VerticalContentGrid from "../common/VerticalContentGrid";
import { useUserService } from "../../hooks/useUserService";
import { CircularProgress } from "@mui/material";

export default function EmailVerification() {
  const userService = useUserService();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await userService.verifyEmail(token);
        setVerified(true);
      } catch (e) {
        setError(true);
      }
    };
    verifyEmail();
  }, [token]);

  return (
    <VerticalContentGrid gap={2}>
      {error ? (
        <MuiAlert variant="filled" severity="error">
          Virheellinen sähköpostin vahvistuslinkki
        </MuiAlert>
      ) : !verified ? (
        <CircularProgress size={100} />
      ) : (
        <>
          <Grid item width="100%">
            <Typography variant="h4">Sähköpostin vahvistaminen onnistui!</Typography>
          </Grid>
          <Grid item width="100%">
            <Typography variant="body1">Sähköpostiosoitteesi on vahvistettu. Voit nyt jatkaa palvelun käyttöä kirjautumalla sisään.</Typography>
          </Grid>
          <Grid item width="100%">
            <Button
              color="button"
              variant="contained"
              onClick={() => navigate("/login")}
              fullWidth
            >
            Kirjaudu sisään
            </Button>
          </Grid>
        </>
      )}
    </VerticalContentGrid>
  );
}
