import * as uuid from "uuid";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import InputTextField from "../common/form/InputTextField";
import LoginIcon from "@mui/icons-material/Login";
import useToken from "../../hooks/useToken.js";
import { useUserService }from "../../hooks/useUserService.js";
import { getEIdentAuthURL, eIdentAuthStateStorageKey } from "../../util/eident";
import VerticalContentGrid from "../common/VerticalContentGrid";
import { useIdentificationService } from "../../hooks/useIdentificationService";
import { useConfirmationContext } from "../../contexts/ConfirmationContext";
import { termsOfService } from "../../config";

export default function Login({ redirect = null }) {
  const userService = useUserService();
  const [, setToken] = useToken();
  const passwordRef = useRef("");
  const emailRef = useRef("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const identificationService = useIdentificationService();
  const confirmationContext = useConfirmationContext();
  const [resendVerificationEmail, setResendVerificationEmail] = useState(false);
  const [emailResent, setEmailResent] = useState(false);
  const [emailToVerify, setEmailToVerify] = useState("");

  const submit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const token = await userService.login({
        email: emailRef.current.value,
        password: passwordRef.current.value
      });
      if (token?.action && token?.action === "resend_verification_email") {
        setResendVerificationEmail(true);
        setEmailToVerify(emailRef.current.value);
      } else {

        if (window.dataLayer) {
          window.dataLayer.push({
            event: "login",
          });
        }

        setToken(token);
        setResendVerificationEmail(false);
        setEmailToVerify("");
        navigate(redirect ?? "/homepage");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerificationEmail = async () => {
    const data = {"email": emailToVerify};
    await userService.resendVerificationEmail(data);
    setEmailResent(true);
  };

  const eIdentAuth = async () => {
    const tosAccepted = await confirmationContext.waitForConfirmation({
      message: termsOfService,
      acceptMessage: "Hyväksy käyttöehdot",
      declineMessage: "Peruuta",
    });
    if (!tosAccepted) {
      return;
    }

    const eIdentConfig = await identificationService.getConfig();
    const eIdentState = uuid.v4();
    sessionStorage.setItem(eIdentAuthStateStorageKey, eIdentState);
    window.location.href = getEIdentAuthURL(eIdentState, eIdentConfig);
  };

  return resendVerificationEmail ? (
    <VerticalContentGrid gap={2}>
      {!emailResent && (
        <>
          <Grid item width="100%">
            <Typography variant="h4">Sähköpostiasi ei ole vielä vahvistettu</Typography>
          </Grid>
          <Grid item width="100%">
            <Button
              color="button"
              variant="contained"
              onClick={handleResendVerificationEmail}
              fullWidth
            >
            Lähetä vahvistuslinkki uudelleen
            </Button>
          </Grid>
        </>
      )}
      {emailResent && (
        <>
          <Grid item>
            <Typography variant="h4">
                Vahvistuslinkki on lähetetty sähköpostiisi
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Huom! Viesti saattaa mennä joissain sähköposteissa roskapostiin, joten tarkistathan myös sieltä.
            </Typography>
          </Grid>
          <Grid item container width="100%" justifyContent="end">
            <Button onClick={() => navigate("/")}>
                Kirjaudu sisään
            </Button>
          </Grid>
        </>
      )}
    </VerticalContentGrid>
  ) : (
    <form onSubmit={submit} style={{ height: "fit-content" }}>
      <VerticalContentGrid>
        <Grid item container justifyContent="center" alignItems="center" gap={2}>
          <LoginIcon fontSize="large" />
          <Typography variant="h4">Kirjaudu sisään</Typography>
        </Grid>
        <Grid item container direction="column">
          <InputTextField label="Sähköposti" inputRef={emailRef} autoFocus />
          <InputTextField label="Salasana" inputRef={passwordRef} type="password" />
        </Grid>
        <Grid item width="100%">
          <Button fullWidth variant="contained" type="submit" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Jatka"}
          </Button>
        </Grid>
        <Grid item container direction="column">
          <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
            <Typography>Oletko uusi käyttäjä?</Typography>
            <Button onClick={eIdentAuth}>Rekisteröidy</Button>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
            <Button component={Link} to="/forgot-password">Unohtuiko salasana?</Button>
          </Grid>
        </Grid>
      </VerticalContentGrid>
    </form>
  );
}

