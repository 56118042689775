import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function ToggleOption({ options, selectedOption, setSelectedOption }) {
  const handleToggle = (event, option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <ToggleButtonGroup 
        exclusive 
        value={selectedOption}
        onChange={handleToggle}
        size="medium">
        {options.map(option => (
          <ToggleButton
            key={option.key}
            value={option.key}
            sx={{ borderRadius: "16px" }}
            variant={option.key === selectedOption ? "contained" : "outlined"}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
}