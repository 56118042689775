import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputTextField from "../common/form/InputTextField";
import { useUserContext } from "../../contexts/UserContext";
import { Alert, CircularProgress, Grid, Snackbar } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CheckIcon from "@mui/icons-material/Check";
import VerticalContentGrid from "../common/VerticalContentGrid";
import { validation as signupValidation } from "../../shared/model/signup";
import { useValidation } from "../../hooks/useValidation";
import { useUserService } from "../../hooks/useUserService";
import { useNavigate } from "react-router-dom";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { formatIban } from "../../util/iban";

const labels = {
  phoneNumber: "Puhelinnumero",
  address: "Lähiosoite",
  zipcode: "Postinumero",
  city: "Postitoimipaikka",
  bankAccount: "Tilinumero (IBAN)",
};

export default function UserSettings() {
  const userService = useUserService();
  const { user, loading, error, loadUser } = useUserContext();
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const apiErrorContext = useApiErrorContext();

  const validation = {
    phoneNumber: useValidation(signupValidation.phoneNumber, loading),
    address: useValidation(signupValidation.address, loading),
    zipcode: useValidation(signupValidation.zipcode, loading),
    city: useValidation(signupValidation.city, loading),
    bankAccount: useValidation(signupValidation.bankAccount, loading),
  };

  const submit = async (event) => {
    event.preventDefault();

    try {
      setSaving(true);

      const errors = Object.entries(validation)
        .map(([key, validation]) => ([ key, validation.validate() ]))
        .filter(([key, error]) => error !== null);

      if (errors.length > 0) {
        const error = errors.map(([key, error]) => `- ${labels[key] ?? "N/A"}: ${error}`).join("\n");
        apiErrorContext.addError(`Tiedoissa on virheitä:\n\n${error}`);
        return;
      }

      const data = Object.entries(validation).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value.inputRef.current.value,
      }), {});

      await userService.modify(user.id, data);
      await loadUser();
      setSuccess(true);
    } finally {
      setSaving(false);
    }
  };

  const resetPassword = () => {
    navigate("/forgot-password", { state: { email: user?.email ?? "" }});
  };

  const changeEmail = () => {
    navigate("/change-email");
  };

  useEffect(() => {
    const inputElement = validation.bankAccount.inputRef.current;
    if (loading || error || !inputElement) {
      return;
    }

    const updateValue = () => {
      inputElement.value = formatIban(inputElement.value);
    };
    updateValue();
    inputElement.addEventListener("input", updateValue);
    return () => {
      inputElement.removeEventListener("input", updateValue);
    };
  }, [loading, error, validation.bankAccount.inputRef]);

  return (
    <form onSubmit={submit} style={{ height: "fit-content" }}>
      <VerticalContentGrid>
        {error ? (
          <Alert variant="filled" severity="error">
            Käyttäjän tietojen lataaminen epäonnistui
          </Alert>
        ) : loading ? (
          <CircularProgress size={50} />
        ) : (
          <>
            <Grid item container justifyContent="center" alignItems="center" gap={2} paddingBottom={2}>
              <AccountCircleOutlinedIcon fontSize="large" />
              <Typography variant="h4">Tilin asetukset</Typography>
            </Grid>
            <Grid item container>
              <InputTextField label="Nimi" defaultValue={user.name} disabled endAdornment={<CheckIcon />} />
              <InputTextField label="Sähköpostiosoite" type="email" disabled defaultValue={user.email} endAdornment={<CheckIcon />} />
              <InputTextField label={labels.phoneNumber} type="tel" autoFocus defaultValue={user.phone_number} {...validation.phoneNumber} />
              <InputTextField label={labels.bankAccount} defaultValue={user.bank_account_number} {...validation.bankAccount} />
              <InputTextField label={labels.address} defaultValue={user.address} {...validation.address} />
              <InputTextField label={labels.zipcode} defaultValue={user.zipcode} {...validation.zipcode} />
              <InputTextField label={labels.city} defaultValue={user.city} {...validation.city} />
            </Grid>
            <Grid item width="100%">
              <Button variant="contained" type="submit" fullWidth>
                {!saving ? "Tallenna" : <CircularProgress size={24} color="inherit" />}
              </Button>
            </Grid>
            <Grid item container justifyContent="end">
              <Button onClick={changeEmail}>
                Vaihda sähköpostiosoite
              </Button>
            </Grid>
            <Grid item container justifyContent="end" paddingBottom={6}>
              <Button onClick={resetPassword}>
                Vaihda salasanaa
              </Button>
            </Grid>
            <Snackbar open={success} onClose={() => setSuccess(false)} autoHideDuration={5000}>
              <Alert severity="success">
                  Tietojen päivittäminen onnistui
              </Alert>
            </Snackbar>
          </>
        )}
        
      </VerticalContentGrid>
    </form>
  );
}
