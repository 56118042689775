import React, { useRef, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineButton from "@mui/icons-material/HelpOutline";
import { FormHelperText, styled } from "@mui/material";

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  position: "relative",
  top: 12,
  left: -12,
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  position: "relative",
  left: -12,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& input": {
    padding: "8px 12px",
  },
}));

const StyledHelpOutlineButton = styled(HelpOutlineButton)(({ theme }) => ({
  position: "relative",
  top: 5,
}));

export default function DropdownOption(
  {
    label, 
    options, 
    selectedOption, 
    onChange,
    inputRef,
    tooltip,
    onOptionChange,
    required,
    error,
    errorText,
    ...passThroughProps}) {


  useEffect(() => {
    if (inputRef) {
      inputRef.current = inputRef.current.node;

      // only sync ref to parent component when an option was selected
      // do not sync the inital ("") empty value to not trigger validation instantly on page load
      if (selectedOption) {
        onOptionChange(inputRef);   // sync back inputRef for validation
      }
    }
  }, [inputRef, onOptionChange, selectedOption]);

  const handleSelect = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth sx={{ paddingBottom: "25px" }} size="small" error={error} required={required}>
      <StyledInputLabel shrink>{label}&nbsp;
        {tooltip && ( <Tooltip title={tooltip}>
          <StyledHelpOutlineButton />
        </Tooltip>)}
      </StyledInputLabel>
      <StyledSelect
        inputRef={ (el) => {
          inputRef.current = el;
        }}
        value={selectedOption}
        onChange={handleSelect}
        error={error}
        {...passThroughProps}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </StyledSelect>
      <StyledFormHelperText error>{error && errorText ? String(errorText) : " "}</StyledFormHelperText>
    </FormControl>
  );
}
