import { useMemo } from "react";
import axios from "axios";
import { useAuth } from "./useAuth.js";
import { useApiErrorContext, wrapWithErrorReporting } from "../contexts/ApiErrorContext.js";

export const usePaymentService = () => {
  const auth = useAuth();
  const apiErrorContext = useApiErrorContext();
  const basePath = "/api/payment";

  return useMemo(() => wrapWithErrorReporting(
    {
      getCheckoutUrl: async (transactionId) =>
        (await axios.post(`${basePath}/checkout-url`, { transactionId }, auth))?.data,
    },
    apiErrorContext.addError,
  ), [auth, apiErrorContext.addError]);
};
