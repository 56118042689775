import { useMemo } from "react";
import axios from "axios";
import { useAuth } from "./useAuth.js";
import {
  useApiErrorContext,
  wrapWithErrorReporting,
} from "../contexts/ApiErrorContext.js";

export const useUserService = () => {
  const auth = useAuth();
  const apiErrorContext = useApiErrorContext();
  const basePath = "/api/users";

  return useMemo(() => wrapWithErrorReporting(
    {
      signup: async (data) => (await axios.post(`${basePath}/signup`, data))?.data,
      login: async (data) => (await axios.post(`${basePath}/login`, data))?.data,
      profile: async (data) => (await axios.get(`${basePath}/profile`, auth))?.data,
      modify: async (id, data) => (await axios.put(`${basePath}/${id}`, data, auth))?.data,
      verifyEmail: async (token) => (await axios.get(`${basePath}/verify-email/${token}`))?.data,
      resendVerificationEmail: async (data) => (await axios.post(`${basePath}/resend-verification-email`, data))?.data,
      sendPasswordResetEmail: async data => (await axios.post(`${basePath}/forgot-password`, data))?.data,
      validatePasswordResetToken: async token => (await axios.get(`${basePath}/reset-password/${token}`))?.data,
      handleEmailChange: async (id, data) => (await axios.put(`${basePath}/change-email/${id}`, data, auth))?.data,
      reAuthenticate: async (id, data) => (await axios.post(`${basePath}/re-authenticate/${id}`, data, auth))?.data,
      resetPassword: async (data) => (await axios.post(`${basePath}/reset-password`, data))?.data,
    },
    apiErrorContext.addError,
  ), [auth, apiErrorContext.addError]);
};
