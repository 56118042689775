import React, { createContext, useContext, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

const ConfirmationContext = createContext();

const ConfirmationDialog = ({
  open,
  message,
  acceptMessage,
  declineMessage,
  onAccept,
  onDecline,
}) => {
  return (
    <Dialog open={open} onClose={onDecline} maxWidth="md">
      <DialogContent>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", padding: 2 }}>
        <Button onClick={onDecline}>
          {declineMessage}
        </Button>
        <Button onClick={onAccept} variant="contained" autoFocus>
          {acceptMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmationProvider = ({ children }) => {
  const confirm = useRef(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    message: "",
    acceptMessage: "",
    declineMessage: "",
  });

  const waitForConfirmation = async (data) => {
    setOpen(true);
    setData(data);
    await new Promise(resolve => setTimeout(resolve, 100));
    return new Promise(resolve => {
      confirm.current = resolve;
    });
  };

  const onAccept = () => {
    confirm.current(true);
    setOpen(false);
  };

  const onDecline = () => {
    confirm.current(false);
    setOpen(false);
  };

  return (
    <ConfirmationContext.Provider value={{ waitForConfirmation }}>
      {children}
      <ConfirmationDialog open={open} onAccept={onAccept} onDecline={onDecline} {...data} />
    </ConfirmationContext.Provider>
  );
};

export const useConfirmationContext = () => {
  const context = useContext(ConfirmationContext);
  if (context === undefined)
    throw new Error("ConfirmationContext must be used within it's provider");
  return context;
};

export default ConfirmationProvider;
