import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      md: 800,
    },
  },
  palette: {
    primary: {
      main: "#5B53A8",
      light: "#7C75BF",
      contrastText: "#E5E1E6",
      dark: "#4D478F"
    },
    action: {
      disabledBackground: "#3f3A78", 
      disabled: "#E5E1E6"
    },
    button: {
      main: "#5B53A8",
      contrastText: "#E5E1E6",
      dark: "#4D478F"
    },
    inputField: {
      main: "#5B53A8",
      secondary: "#C9C5D0"
    },
    blackButton: {
      main: "#2E2D31",
      contrastText: "#E5E1E6"
    },
    blackBackground: {
      main: "#2E2D31",
      contrastText: "#E5E1E6"
    },
    whiteBackground: {
      main: "#E5E1E6",
      contrastText: "#E5E1E6"
    },
    greyBackground: {
      main: "#C9C5D0",
      contrastText: "#E5E1E6"
    },
  },
  typography: {
    fontFamily: [
      "\"IBM Plex Sans\"",
    ].join(","),
    h1: {
      fontSize: "99px"
    },
    h2: {
      fontSize: "62px"
    },
    h3: {
      fontSize: "49px"
    },
    h4: {
      fontSize: "35px"
    },
    h5: {
      fontSize: "25px"
    },
    h6: {
      fontSize: "21px"
    },
    body1: {
      fontSize: "16px"
    },
    body2: {
      fontSize: "14px"
    },
    button: {
      fontSize: 14,
      fontWeight: 700,
    }
  },
  components: {
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          minHeight: "10px",
          marginLeft: "2px",
        },
      },
    },
  },
});

