import Transaction from "../shared/model/transaction.js";

export const actionNames = {
  [Transaction.UserRole.Buyer]: {
    [Transaction.Status.AwaitingPayment]: "Maksa",
    [Transaction.Status.PickedUp]: ["Vahvista vastaanotetuksi", "Ilmoita ongelma"],
    //[Transaction.Status.ReadyForPickup]: "Vahvista vastaanotetuksi",
  },
  [Transaction.UserRole.Seller]: {
    [Transaction.Status.Paid]: "Lähetä",
  },
};

export const getActionName = (status, role, receiver_id) => {
  if (receiver_id === null || receiver_id === undefined) {
    return null;
  }
  return actionNames?.[role]?.[status];
};

export const formatCurrency = (currency) => {
  const currencies = {
    EUR: " €"
  };

  return currencies[currency] ?? currency;
};

export const formatPrice = (price, currency) =>
  isNaN(parseFloat(price)) ? "" : String(parseFloat(price).toFixed(2)) + formatCurrency(currency);

export const formatDate = (input) => {
  if (input === "" || input === undefined || input === null) {
    return "";
  }
  const date = input instanceof Date ? input : new Date(input);
  return `${date.toLocaleDateString("FI")} ${date.toLocaleTimeString("FI", {
    timeStyle: "short"    // wihtout seconds
  })}`;
};
