import { useMemo } from "react";
import axios from "axios";
import { useApiErrorContext, wrapWithErrorReporting } from "../contexts/ApiErrorContext";

export const useIdentificationService = () => {
  const identificationPath = "/api/identification";
  const apiErrorContext = useApiErrorContext();

  return useMemo(() => wrapWithErrorReporting(
    {
      getConfig: async () => (await axios.get(`${identificationPath}/config`))?.data,
      identify: async data => (await axios.post(`${identificationPath}`, data))?.data,
    },
    apiErrorContext.addError,
  ), [apiErrorContext.addError]);
};

