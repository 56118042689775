import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { TOKEN_RENEW_MS } from "../config";

const TOKEN_STORAGE_KEY = "safepayment-user-token";
const TokenContext = createContext();

const renewToken = async (currentToken) => {
  const response = await axios.get(
    "/api/auth/renew",
    { headers: { Authorization: `Bearer ${currentToken}` }}
  );
  return response.data;
};

export const TokenProvider = ({ children }) => {
  const [token, _setToken] = useState(localStorage.getItem(TOKEN_STORAGE_KEY));

  const setToken = useCallback((newToken) => {
    _setToken(newToken);
    if (newToken !== null) {
      localStorage.setItem(TOKEN_STORAGE_KEY, newToken);
    } else {
      localStorage.removeItem(TOKEN_STORAGE_KEY);
    }
  }, []);

  useEffect(() => {
    const renew = async () => {
      if (!token) {
        return;
      }

      try {
        setToken(await renewToken(token));
      } catch (e) {
        setToken(null);
      }
    };

    renew();
    const renewInterval = window.setInterval(renew, TOKEN_RENEW_MS);
    return () => {
      window.clearInterval(renewInterval);
    };
  }, [token, setToken]);

  return <TokenContext.Provider value={{ token, setToken }}>{children}</TokenContext.Provider>;
};

const useToken = () => {
  const context = useContext(TokenContext);
  if (context === undefined)
    throw new Error("TokenContext must be used within it's provider");
  return [context.token, context.setToken];
};

export default useToken;
