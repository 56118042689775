import React from "react";
import Grid from "@mui/material/Grid";

export default function VerticalContentGrid({
  children,
  sx,
  padding = 2,
  rowSpacing = 2,
  ...props
}) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      padding={padding}
      rowSpacing={rowSpacing}
      boxSizing="border-box"
      sx={{
        margin: "auto",
        minHeight: "100vh",
        width: { md: 480, xs: "100%" },
        paddingBottom: { md: 0, xs: 8 },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Grid>
  );
}