import React, { createContext, useState, useRef, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { Dialog, Button, useMediaQuery, Grid, DialogContent, DialogActions, Typography } from "@mui/material";
import InputTextField from "../components/common/form/InputTextField";
import { useValidation } from "../hooks/useValidation";
import Joi from "joi";
import { TRACKING_ID_MAX_LEN } from "../shared/validationConsts.js";
import { useApiErrorContext } from "./ApiErrorContext";
import { useTransactionService } from "../hooks/useTransactionService";

export const TrackingIdContext = createContext();

const TrackingIdContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const apiErrorContext = useApiErrorContext();
  const transactionService = useTransactionService();
  const confirm = useRef(null);

  const validation = useValidation(Joi.string().max(TRACKING_ID_MAX_LEN).regex(/^JJFI\d+$/).messages({
    "string.empty": "Pakollinen",
    "string.pattern.base": "Lähetystunnuksen on oltava muotoa 'JJFI123456789'",
    "string.max": "Maksimipituus 30 merkkiä",
  }));

  const waitForConfirmation = async (transactionId) => {
    setOpen(true);
    setTransactionId(transactionId);
    await new Promise(resolve => setTimeout(resolve, 100));
    return new Promise(resolve => {
      confirm.current = resolve;
    });
  };

  const onAccept = async () => {
    try {
      setLoading(true);
      const error = validation.validate();
      if (error !== null) {
        apiErrorContext.addError(error);
        return;
      }
      await transactionService.addTrackingId(transactionId, {"trackingId": validation.inputRef.current.value});
      confirm.current(true);
      setOpen(false);
    } finally {
      setLoading(false);
    }
  };

  const onDecline = () => {
    confirm.current(false);
    setOpen(false);
  };

  const formatTrackingId = (event) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[a-zA-Z]/g, function(letter) {
      return letter.toUpperCase();
    });
  };

  return (
    <TrackingIdContext.Provider value={{ waitForConfirmation }}>
      {children}
      <Dialog
        open={open}
        onClose={onDecline} 
        fullScreen={isMobile}
        PaperProps={{ sx: { width: isMobile ? "100%" : 400 }}}
      >
        <DialogContent>
          <Typography variant="body1" rowSpacing={2} sx={{ whiteSpace: "pre-line" }}>
            Syötä Postin lähetystunnus. Tunnus on muotoa JJFI123456789.
          </Typography>
          <Grid item width="100%">
            <InputTextField 
              fullWidth 
              autoFocus 
              label="Lähetystunnus" 
              onChange={formatTrackingId} 
              {...validation} 
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", padding: 2 }}>
          <Button onClick={onDecline}>
            Peruuta
          </Button>
          <Button onClick={onAccept} variant="contained" disabled={loading}>
            {loading ? "Ladataan" : "Vahvista"}
          </Button>
        </DialogActions>
      </Dialog>
    </TrackingIdContext.Provider>
  );
};

export default TrackingIdContextProvider;
