import AddSharpIcon from "@mui/icons-material/AddSharp";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { CreateTransactionContext as CreatePrepaymentContext } from "../../contexts/CreateTransactionContext";
import { useLoadingContext, withLoading } from "../../contexts/LoadingContext";
import { useValidation } from "../../hooks/useValidation";
import Prepayment from "../../shared/model/prepayment";
import ManageProductImages from "../common/ManageProductImages";
import VerticalContentGrid from "../common/VerticalContentGrid";

export default function PicturesAndDescription() {
  const createPrepaymentContext = useContext(CreatePrepaymentContext);
  const prepaymentId = createPrepaymentContext.current.id;
  const [images, setImages] = useState(createPrepaymentContext.current.images);
  const navigate = useNavigate();
  const loadingContext = useLoadingContext();
  const apiErrorContext = useApiErrorContext();
  const descriptionValidation = useValidation(Prepayment.validation.description);

  const loading = loadingContext.isLoading.length > 0;

  const handleContinue = async () => withLoading(loadingContext, async () => {
    /*const error = descriptionValidation.validate();
    if (error !== null) {
      apiErrorContext.addError(error);
      return;
    }*/

    createPrepaymentContext.modify({
      ...createPrepaymentContext.current,
      images,
      //description: descriptionValidation.inputRef.current.value,
    });
    navigate("/create-prepayment/additional-information");
  });

  useEffect(() => {
    if (prepaymentId === null) {
      navigate("/create-prepayment");
    }
  }, [prepaymentId, navigate]);

  return (
    <VerticalContentGrid>
      <Grid item container justifyContent="center" alignItems="center" gap={2}>
        <AddSharpIcon fontSize="large" />
        {/*<Typography variant="h4">Luo kauppa</Typography>*/}
        <Typography variant="h4">Lisää kuvia</Typography>
      </Grid>
      <Grid item>
        <ManageProductImages
          images={images}
          onImagesChange={setImages}
        />
      </Grid>
      {/*  <Grid item width="100%">
        <InputTextField
          label="Tuotteen kuvaus"
          fullWidth
          autoFocus
          multiline
          defaultValue={createTransactionContext.current.description}
          {...descriptionValidation} />
      </Grid>*/}
      <Grid item container justifyContent="space-between">
        <Button variant="outlined" component={Link} to="/create-prepayment">
          Mene takaisin
        </Button>
        <Button variant="contained" onClick={handleContinue} disabled={loading}>
          Jatka
        </Button>
      </Grid>
    </VerticalContentGrid>
  );
}

