import { Alert, Snackbar, styled } from "@mui/material";
import { AxiosError } from "axios";
import React, { createContext, useCallback, useContext, useState } from "react";

const AUTOHIDE_TIME = 5000000;

const ApiErrorContext = createContext();

function ApiErrorProvider({ children }) {
  const [errors, setErrors] = useState([]);
  const [visible, setVisible] = useState(false);

  const addError = useCallback((error, keep = false) => {
    setErrors(prev => [...prev, error]);
    setVisible(true);
    if (!keep) {
      setTimeout(() => setVisible(false), AUTOHIDE_TIME);
    }
  }, []);

  const hide = useCallback(() => setVisible(false), []);

  const error = errors[errors.length - 1];

  return (
    <ApiErrorContext.Provider value={{ addError, hide }}>
      <Snackbar
        open={visible && errors.length > 0}
        onClose={hide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert variant="filled" severity="error" onClose={hide}>
          <span style={{ whiteSpace: error && error.includes("\n") ? "pre-line" : "normal" }}>
            {error}
          </span>
        </Alert>
      </Snackbar>
      {children}
    </ApiErrorContext.Provider>
  );
}

export const useApiErrorContext = () => {
  const context = useContext(ApiErrorContext);
  if (context === undefined)
    throw new Error("ApiErrorContext must be used within it's provider");
  return context;
};

export const withErrorReporting = (func, reporter) => {
  return async (...args) => {
    try {
      const result = await func(...args);
      return result;
    } catch (error) {
      const errorMessage = error instanceof AxiosError ? error.response.data : String(error);
      reporter(`Virhe: ${errorMessage}`);
      throw error;
    }
  };
};

export const wrapWithErrorReporting = (object, reporter) => {
  return Object.entries(object).reduce((acc, [name, method]) => ({
    ...acc,
    [name]: withErrorReporting(method, reporter),
  }), {});
};

export default ApiErrorProvider;