import React, { useEffect, useState } from "react";
import * as uuid from "uuid";
import Typography from "@mui/material/Typography";
import {
  getEIdentAuthURL,
  eIdentAuthStateStorageKey,
  invitationDataStorageKey,
} from "../../util/eident";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import VerticalContentGrid from "../common/VerticalContentGrid";
import { useInvitationService } from "../../hooks/useInvitationService";
import { CircularProgress } from "@mui/material";
import { useIdentificationService } from "../../hooks/useIdentificationService";
import { useConfirmationContext } from "../../contexts/ConfirmationContext";
import { termsOfService } from "../../config";

export default function Invitation() {
  const invitationService = useInvitationService();
  const [error, setError] = useState(false);
  const [invitationData, setInvitationData] = useState(null);
  const identificationService = useIdentificationService();
  const confirmationContext = useConfirmationContext();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  const eIdentAuth = async () => {
    const tosAccepted = await confirmationContext.waitForConfirmation({
      message: termsOfService,
      acceptMessage: "Hyväksy käyttöehdot",
      declineMessage: "Peruuta",
    });
    if (!tosAccepted) {
      return;
    }

    const eIdentConfig = await identificationService.getConfig();
    const eIdentState = uuid.v4();
    sessionStorage.setItem(eIdentAuthStateStorageKey, eIdentState);
    window.location.href = getEIdentAuthURL(eIdentState, eIdentConfig);
  };

  useEffect(() => {
    const getInvitationData = async () => {
      try {
        setError(false);
        setInvitationData(await invitationService.find(code));
      } catch (e) {
        setError(true);
      }
    };
    if (code) {
      getInvitationData();
    }
  }, [code, invitationService]);

  useEffect(() => {
    sessionStorage.setItem(invitationDataStorageKey, JSON.stringify({ ...invitationData, code }));
  }, [invitationData]);

  return (
    <VerticalContentGrid gap={2}>
      {error ? (
        <MuiAlert variant="filled" severity="error">
          Virheellinen kutsulinkki
        </MuiAlert>
      ) : !invitationData ? (
        <CircularProgress size={100} />
      ) : (
        <>
          <Typography variant="h4">
            Sinut on kutsuttu käyttämään turvamaksut.fi-palvelua
          </Typography>
          <Typography variant="body1">
            Käyttäjä {invitationData.inviterName} on kutsunut sinut käyttämään
            turvamaksut&#8204;.fi-palvelua koskien tuotetta <strong>{invitationData.itemName}</strong>.
            Kutsu on lähetetty sähköpostiosoitteeseen <br /><strong>{invitationData.email}</strong>.
          </Typography>
          <Button
            color="button"
            variant="contained"
            onClick={eIdentAuth}
            fullWidth
          >
            Rekisteröidy
          </Button>
        </>
      )}
    </VerticalContentGrid>
  );
}
