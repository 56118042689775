import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme, useMediaQuery, Typography, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import StoreIcon from "@mui/icons-material/Store";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ToggleOption from "./ToggleOption";

const HoverableTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
  },
}));

export default function SortableFilterableTable({
  columns,
  rows,
  defaultSortBy = null,
  defaultSortDirection = "desc"
}) {
  const [sortBy, setSortBy] = useState({ column: defaultSortBy, direction: defaultSortDirection });
  const [roleFilter, setRoleFilter] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (!columns?.length > 0) {
    return <Typography>Ei dataa</Typography>;
  }

  const roleToggleOptions = [
    { key: "myyjä", label: <StoreIcon sx={{ fontSize: 15 }}></StoreIcon> },
    { key: "ostaja", label: <ShoppingCartIcon sx={{ fontSize: 15 }}></ShoppingCartIcon> },
  ];

  const onHeaderClick = (column) => {
    if (sortBy.column === column) {
      setSortBy((old) => ({ column, direction: old.direction === "asc" ? "desc" : "asc" }));
    } else {
      setSortBy({ column, direction: "desc" });
    }
  };

  const visibleColumns = columns.filter((column) => {
    if (isMobile) {
      return !column.hideOnMobile;
    } else {
      return !column.hideOnDesktop;
    }
  });

  const sortedRows = rows.sort((a, b) => {
    const column = sortBy.column ?? columns[0].key;
    const result = compare(a.data[column], b.data[column]);
    return sortBy.direction === "asc" ? result : -result;
  });

  const filteredAndSortedRows = 
    roleFilter ? 
      sortedRows.filter((row) => row.data.role === roleFilter) :
      sortedRows;

  return (
    <Table style={{ tableLayout: "fixed" }}> 
      <TableHead>
        <TableRow>
          {visibleColumns.map((column) => (
            <TableCell 
              key={column.key}
              align={column.style?.align}
              sx={column.style}>
              {column.filterable ? (
                <>
                  <ToggleOption
                    options={roleToggleOptions}
                    selectedOption={roleFilter}
                    setSelectedOption={setRoleFilter}
                  />
                </>    
              ) : (
                <TableSortLabel
                  active={sortBy.column === column.key}
                  direction={sortBy.direction}
                  onClick={() => onHeaderClick(column.key)}
                  sx={{ fontWeight: "bold" }}
                >
                  {column.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredAndSortedRows.map((row) => (
          <HoverableTableRow key={row.id} tabIndex={-1} hover onClick={row.onClick}>
            {visibleColumns.map((column) => (
              <TableCell 
                key={`${row.id}-${column.key}`}
                align={column.style?.align}
                sx={column.style}>
                {row.render?.[column.key]
                  ? row.render[column.key](row.data[column.key], row.data)
                  : row.data[column.key]}
              </TableCell>
            ))}
          </HoverableTableRow>
        ))}
      </TableBody>
    </Table>
  );
}

SortableFilterableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    hideOnMobile: PropTypes.bool,
    sortable: PropTypes.bool,
    filterable: PropTypes.bool,
    sx: PropTypes.object
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    render: PropTypes.object,
  })).isRequired,
  defaultSortBy: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(["asc", "desc"]),
};

const normalize = (val) => {
  if (typeof val === "number" && !isNaN(val)) {
    return val;
  } else if (typeof val === "number" && isNaN(val)) {
    return 0;
  }

  return String(val ?? "").toLowerCase();
};


const compare = (a, b) => {
  const aNorm = normalize(a);
  const bNorm = normalize(b);

  if (typeof aNorm === "number" && typeof bNorm === "number") {
    return aNorm - bNorm;
  } else if (typeof aNorm === "number" && typeof bNorm !== "number") {
    return aNorm - 0;
  } else if (typeof aNorm !== "number" && typeof bNorm === "number") {
    return 0 - bNorm;
  } else if (aNorm > bNorm) {
    return -1;
  } else if (aNorm < bNorm) {
    return 1;
  }

  return 0;
};
