import { Buffer } from "buffer";

export const getJwtPayLoad = (jwt) => {
  if(typeof jwt !== "string") {
    return null;
  }

  const split = jwt.split(".", 3);
  if(split.length !== 3) {
    return null;
  }

  return JSON.parse(Buffer.from(split[1], "base64").toString());
};
