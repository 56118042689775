import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { React, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LogoutView from "./components/auth/LogoutView.js";
import PrivateOutlet from "./components/auth/PrivateOutlet.js";
import CreatePrepayment from "./components/create-prepayment/CreatePrepayment";
import PicturesAndDescriptionPrepayment from "./components/create-prepayment/PicturesAndDescription";
import PrepaymentAdditionalInformation from "./components/create-prepayment/AdditionalInformation";
import AdditionalInformation from "./components/create-transaction/AdditionalInformation";
import CreateTransaction from "./components/create-transaction/CreateTransaction";
import PicturesAndDescription from "./components/create-transaction/PicturesAndDescription";
import Homepage from "./components/homepage/Homepage";
import SafetyAdvice from "./components/homepage/SafetyAdvice.js";
import Invitation from "./components/invitation/Invitation";
import Login from "./components/login/Login.js";
import ForgotPassword from "./components/reset-password/ForgotPassword";
import ResetPassword from "./components/reset-password/ResetPassword";
import Sidebar from "./components/sidebar/Sidebar";
import Identify from "./components/sign-up/eident/Identify";
import EmailVerification from "./components/sign-up/EmailVerification";
import SignUp from "./components/sign-up/SignUp.js";
import Transactions from "./components/transactions/Transactions";
import ChangeEmail from "./components/user-settings/ChangeEmail.js";
import UserSettings from "./components/user-settings/UserSettings.js";
import { Style } from "./config";
import { useUserContext } from "./contexts/UserContext";
import User from "./shared/model/user";

const RootContainer = styled("div")({
  width: "100%",
  height: "100vh",
});

const usePageViewTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "page_view",
      page: pagePath,
    });
  }, [location]);
};

function App() {
  const { user } = useUserContext();
  const showSidebar = !!user;

  usePageViewTracking();

  return (
    <RootContainer>
      {showSidebar && <Sidebar />}
      <Box sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        paddingLeft: { md: showSidebar ? `${Style.Sidebar.WidthPx}px` : 0, xs: 0 },
      }}>
        <Routes>
          <Route path="/" element={<PrivateOutlet roles={[User.Role.User, User.Role.Admin]} />}>
            <Route path="/" element={<Navigate to="/homepage" replace />} />
            <Route path="homepage" element={<Homepage />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="user-settings" element={<UserSettings />} />
            <Route path="safety-advice" element={<SafetyAdvice />} />
            <Route path="create-transaction/" element={<CreateTransaction />} />
            <Route path="create-transaction/create-manually" element={<PicturesAndDescription />} />
            <Route path="create-transaction/additional-information" element={<AdditionalInformation />} />
            <Route path="create-prepayment" element={<CreatePrepayment />} />
            <Route path="/create-prepayment/create-manually" element={<PicturesAndDescriptionPrepayment />} />
            <Route path="/create-prepayment/additional-information" element={<PrepaymentAdditionalInformation />} />
            <Route path="*" element={<Navigate to="/homepage" replace />} />
          </Route>
          <Route path="/identify" element={<Identify />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<LogoutView />} />
          <Route path="/invitation" element={<Invitation />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/change-email" element={<ChangeEmail />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Box>
    </RootContainer>
  );
}

export default App;
