import LockResetIcon from "@mui/icons-material/LockReset";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiErrorContext } from "../../contexts/ApiErrorContext.js";
import { useUserService } from "../../hooks/useUserService.js";
import { useValidation } from "../../hooks/useValidation";
import { validation as signupValidation } from "../../shared/model/signup";
import InputTextField from "../common/form/InputTextField.js";
import VerticalContentGrid from "../common/VerticalContentGrid";

const labels = {
  password: "Salasana",
  passwordConfirmation: "Vahvista salasana",
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const userService = useUserService();
  const apiErrorContext = useApiErrorContext();
  const passwordConfirmationRef = useRef(null);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const validation = {
    password: useValidation(signupValidation.password),
  };

  useEffect(() => {
    const validateToken = async () => {
      try {
        await userService.validatePasswordResetToken(token);
        setIsValid(true);
      } catch (e) {
        setError(true);
      }
    };
    validateToken();
  }, [token]);

  const submit = async (event) => {
    event.preventDefault();

    const errors = Object.entries(validation)
      .map(([key, validation]) => ([ key, validation.validate() ]))
      .filter(([key, error]) => error !== null);

    if (errors.length > 0) {
      const error = errors.map(([key, error]) => `- ${labels[key] ?? "N/A"}: ${error}`).join("\n");
      apiErrorContext.addError(`Tiedoissa on virheitä:\n\n${error}`);
      return;
    }

    if (validatePasswordConfirmation() !== null) {
      apiErrorContext.addError("Salasanojen on vastattava toisiaan");
      return;
    }

    try {
      setLoading(true);
      const data = Object.entries(validation).reduce((acc, [key, value]) => {
        const inputValue = value.inputRef.current?.value ?? "";
        return {
          ...acc,
          [key]: inputValue,
        };
      }, {});
      data.token = token;
      await userService.resetPassword(data);
      setSuccess(true);
    } finally {
      setLoading(false);
    }
  };

  const validatePasswordConfirmation = () => {
    const error = passwordConfirmationRef.current?.value !== validation.password.inputRef.current?.value
      ? "Salasanojen on vastattava toisiaan"
      : null;
    
    setPasswordConfirmationError(error);
    return error;
  };

  useEffect(() => {
    const inputElement = passwordConfirmationRef.current;
    if (!inputElement) {
      return;
    }

    const onInput = () => {
      validatePasswordConfirmation();
    };

    const onBlur = () => {
      validatePasswordConfirmation();
    };

    passwordConfirmationRef.current.addEventListener("input", onInput);
    passwordConfirmationRef.current.addEventListener("blur", onBlur);

    return () => {
      inputElement.removeEventListener("input", onInput);
      inputElement.removeEventListener("blur", onBlur);
    };
  }, [passwordConfirmationRef]);

  return (
    <form onSubmit={submit} style={{ height: "fit-content" }}>
      <VerticalContentGrid>
        {error && (
          <MuiAlert variant="filled" severity="error">
            Virheellinen salasanan palautuslinkki
          </MuiAlert>
        )}
        {!error && !isValid && <CircularProgress size={100} />}
        {success ? (
          <>
            <Grid item>
              <Typography variant="h5">Salasanan vaihto onnistui.</Typography>
            </Grid>
            <Grid item width="100%">
              <Button variant="contained" onClick={() => navigate("/login")} fullWidth autoFocus>
                Kirjaudu sisään
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item container justifyContent="center" alignItems="center" gap={2}>
              <LockResetIcon fontSize="large" />
              <Typography variant="h4">Salasanan vaihto</Typography>
            </Grid>
            <Grid item width="100%">
              <InputTextField
                label={labels.password}
                type="password"
                {...validation.password}
                autoFocus
              />
              <InputTextField
                label={labels.passwordConfirmation}
                inputRef={passwordConfirmationRef}
                type="password"
                error={!!passwordConfirmationError}
                errorText={passwordConfirmationError}
              />
            </Grid>
            <Grid item width="100%">
              <Button fullWidth variant="contained" type="submit" disabled={loading}>
                {!loading ? "Vaihda salasana" : <CircularProgress size={24} color="inherit" />}
              </Button>
            </Grid>
          </>
        )}
      </VerticalContentGrid>
    </form>
  );
}




