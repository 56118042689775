import React from "react";
import {
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const LoadingOverlay = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2000,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        width: "100vw"
      }}
    >
      <CircularProgress
        color="primary"
        size={100}
        // compensate for side bar in desktop mode
        sx={{ position: "relative", left: isMobile ? 0 : 120}}
      />
    </div>
  );
};
