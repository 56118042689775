import {
  useMediaQuery,
  useTheme
} from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";
import { useUserContext } from "../../contexts/UserContext";
import VerticalContentGrid from "../common/VerticalContentGrid";

export default function SafetyAdvice() {
  const { user } = useUserContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const safetyAdvice = [
    "Emme koskaan pyydä myyjää kirjautumaan mihinkään linkkiin syöttämään tietoja maksun vastaanottamiseksi. Maksut tulevat automaattisesti ilmoitettuun tilinumeroon, kun tuote on maksettu ja vastaanotettu ostajan osalta.",
    "Ilmoitus maksetusta maksusta tulee rekisteröinnin yhteydessä ilmoitettuun sähköpostiin ja emme siinä yhteydessä pyydä siirtymään mihinkään linkkiin.",
    "Tarkista aina palvelusta että tuote on maksettu. Voit tarkistaa asian klikkaamalla palvelussa myyntikohdetta.",
    "Emme lähetä tekstiviestejä / sähköposteja, joissa pyydetään siirtymään johonkin kolmannen osapuolen palveluun.",
    "Kaupan molempien osapuolten pitää olla rekisteröitynyt, jotta maksaminen on ylipäätään mahdollista.",
    "Emme koskaan kysele luottokorttitietojasi."
  ];

  return (
    <VerticalContentGrid rowSpacing={4}>
      <Grid container item direction="column">
        <Grid item mb={2}>
          <Typography variant="h4">
            Turvallisuusohjeet
          </Typography>
        </Grid>
        <Grid item>
          <List sx={{ width: "100%" }} component="ol">
            {safetyAdvice.map((value, key) => (
              <ListItem
                key={`advice-${key}`}
                sx={{ display: "list-item", listStyleType: "decimal" }}
                pl={2}
              >
                <ListItemText primary={`${value}`} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </VerticalContentGrid>
  );
}

