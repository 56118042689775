import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { FormHelperText, styled } from "@mui/material";

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  position: "relative",
  top: 12,
  left: -12,
  whiteSpace: "normal",
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  position: "relative",
  left: -12,
}));

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  "& input": {
    padding: "8px 12px",
  },
}));

export default function InputTextField(props) {
  const {
    label,
    endAdornment,
    multiline,
    inputRef,
    type,
    defaultValue,
    disabled,
    required,
    error,
    readOnly,
    errorText,
    validate,
    ...passThroughProps
  } = props;

  return (
    <FormControl fullWidth variant="outlined" required={required}>
      <StyledInputLabel shrink position="top" error={error}>
        {label} 
      </StyledInputLabel>
      <StyledInput
        endAdornment={
          <InputAdornment position="end">
            {endAdornment}
          </InputAdornment>
        }
        multiline={multiline} 
        inputRef={inputRef}
        rows="5"
        type={type}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        readOnly={readOnly}
        {...passThroughProps}
      />
      <StyledFormHelperText error>{error && errorText ? String(errorText) : " "}</StyledFormHelperText>
    </FormControl>
  );
}
