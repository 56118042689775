import React, { useContext, useMemo } from "react";
import axios from "axios";
import { useAuth } from "./useAuth.js";
import { usePaymentService } from "./usePaymentService.js";
import Transaction from "../shared/model/transaction.js";
import { useApiErrorContext, wrapWithErrorReporting } from "../contexts/ApiErrorContext.js";
import { useConfirmationContext } from "../contexts/ConfirmationContext.js";
import { TrackingIdContext } from "../contexts/TrackingIdPopupContext.js";

export const useTransactionService = () => {
  const auth = useAuth();
  const paymentService = usePaymentService();
  const apiErrorContext = useApiErrorContext();
  const confirmationContext = useConfirmationContext();
  const transactionsBasePath = "/api/transactions";
  const trackingIdPopupContext = useContext(TrackingIdContext);

  const get = async () => (await axios.get(`${transactionsBasePath}`, auth))?.data;
  const getById = async id => (await axios.get(`${transactionsBasePath}/${id}`, auth))?.data;
  const create = async newObject => (await axios.post(transactionsBasePath, newObject, auth))?.data;
  const modify = async (id, transaction) => (
    await axios.put(`${transactionsBasePath}/${id}`, transaction, auth)
  )?.data;
  const modifyStatus = async (id, status) => (
    await axios.put(`${transactionsBasePath}/${id}/status`, { status }, auth)
  )?.data;
  const addImage = async (id, image) => (
    await axios.post(`${transactionsBasePath}/${id}/image`, image, auth)
  )?.data;
  const removeImage = async (id, name) => (
    await axios.delete(`${transactionsBasePath}/${id}/image/${name}`, auth)
  )?.data;
  const takeScreenshot = async (id, url) => (
    await axios.post(`${transactionsBasePath}/${id}/screenshot`, { url }, auth)
  )?.data;
  const getImages = async id => (
    await axios.get(`${transactionsBasePath}/${id}/images`, auth)
  )?.data;
  const addTrackingId = async (id, tracking) => (
    await axios.post(`${transactionsBasePath}/${id}/tracking`, tracking, auth)
  )?.data;

  const statusChangeAction = async (transaction, index = null) => {
    const confirmReportProblem = async (transactionId) => {
      const reportProblem = await confirmationContext.waitForConfirmation({
        message: `Keskeytä kauppa ja ota yhteyttä myyjään ja asiakaspalveluun 
        (toimitamme yhteystiedot molemmille)`,
        acceptMessage: "Vahvista",
        declineMessage: "Peruuta",
      });

      if (!reportProblem) {
        return false;
      }
      await modifyStatus(transactionId, Transaction.Status.Problem);
      return true;
    };
    const confirmReceive = async (transactionId) => {
      const confirmed = await confirmationContext.waitForConfirmation({
        message: (
          <>
            Haluatko varmasti vahvistaa tuotteen <br />
            {transaction.name} vastaanotetuksi? <br />
              Vahvista kauppasumman maksu myyjälle.
          </>
        ),
        acceptMessage: "Vahvista",
        declineMessage: "Peruuta",
      });
      if (!confirmed) {
        return false;
      }
      await modifyStatus(transactionId, Transaction.Status.Received);
      return true;
    };
    const actions = {
      // pay transaction dialog modal (Transaction List)
      [Transaction.UserRole.Buyer]: {
        [Transaction.Status.AwaitingPayment]: async (transactionId) => {
          const confirmed = await confirmationContext.waitForConfirmation({
            message: `Haluatko varmasti maksaa tuotteen ${transaction.name}?`,
            acceptMessage: "Siirry maksamaan",
            declineMessage: "Peruuta"
          });
          if (!confirmed) {
            return;
          }
          const checkoutUrl = await paymentService.getCheckoutUrl(transactionId);
          window.location.href = checkoutUrl;
        },
        // confirm received parcel
        //[Transaction.Status.Delivered]: confirmReceive,
        //[Transaction.Status.ReadyForPickup]: confirmReceive,
        [Transaction.Status.PickedUp]: async (transactionId) => {
          if (index === 1) {
            return confirmReportProblem(transactionId); 
          } else {
            return confirmReceive(transactionId); 
          }
        }
      },
      // add tracking
      [Transaction.UserRole.Seller]: {
        [Transaction.Status.Paid]: async (transactionId) => {
          const confirmed = await trackingIdPopupContext.waitForConfirmation(transactionId);
          if (!confirmed) {
            return false;
          }
          return true;
        },
      },
    };

    return await actions?.[transaction.role]?.[transaction.status]?.(transaction.id);
  };

  return useMemo(() => wrapWithErrorReporting(
    {
      get,
      getById,
      create,
      modify,
      modifyStatus,
      addImage,
      removeImage,
      takeScreenshot,
      getImages,
      statusChangeAction,
      addTrackingId,
    },
    apiErrorContext.addError,
  ), [auth, apiErrorContext.addError]);
};

