/**
 * Format string to uppercase letters and four character groups
 */
export const formatIban = (unformatted) =>
  unformatted
    .replaceAll(/\s*/g, "")
    .toUpperCase()
    .split("")
    .reduce((acc, char, idx) => idx % 4 === 0 ? acc + " " + char : acc + char, "")
    .trim();
