import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import useToken from "../../hooks/useToken";
import { getJwtPayLoad } from "../../util/jwt";
import Login from "../login/Login";

function PrivateOutlet({ roles }) {
  const [token] = useToken();
  const location = useLocation();
  const payload = getJwtPayLoad(token);

  const hasPayload = !!payload;
  const hasRole = roles.includes(payload?.role);
  const hasNotExpired = payload?.exp > Date.now() / 1000;

  if (!hasPayload || !hasRole || !hasNotExpired) {
    return <Login redirect={location.pathname + location.search}/>;
  }

  return <Outlet />;
}

export default PrivateOutlet;
