import { useCallback, useEffect, useRef, useState } from "react";


export const useValidation = (joiSchema, loading = false, config = { validationDelay: 500 }) => {
  const timeout = useRef(null);
  const inputRef = useRef("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const validate = useCallback(() => {
    const { error } = joiSchema.validate(inputRef.current?.value);
    const errorMessage = error?.details?.map((err) => err.message)?.join(", ");

    if (error) {
      setError(true);
      setErrorText(error.details.map((err) => err.message).join(", "));
    } else {
      setError(false);
      setErrorText("");
    }

    return error ? errorMessage : null;
  }, [joiSchema]);

  useEffect(() => {
    const { validationDelay } = config;

    const inputElement = inputRef.current;
    if (!inputElement) {
      return;
    }

    const onInput = () => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => validate(), validationDelay);
    };

    const onBlur = () => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => validate(), 100);
    };

    inputRef.current.addEventListener("input", onInput);
    inputRef.current.addEventListener("blur", onBlur);

    return () => {
      inputElement.removeEventListener("input", onInput);
      inputElement.removeEventListener("blur", onBlur);
    };
  }, [joiSchema, config, validate, loading]);

  return {
    error,
    errorText,
    inputRef,
    validate,
  };
};
