import React, { createContext, useState } from "react";
import { useTransactionService } from "../hooks/useTransactionService";
import { imageNameMatcher } from "../util/images";

export const CreateTransactionContext = createContext();

const emptyTransaction = {
  id: null,
  name: "",
  description: "",
  price: "",
  payment_method: "",
  fee: "",
  email: "",
  images: [],
};

export const CreateTransactionProvider = ({ children }) => {
  const transactionService = useTransactionService();
  const [current, setCurrent] = useState(emptyTransaction);
  const [committedImages, setCommittedImages] = useState([]);

  const create = async (role, screenshotUrl = "") => {
    const images = [];
    const { transactionId } = await transactionService.create({ role });

    if (screenshotUrl) {
      await transactionService.takeScreenshot(transactionId, screenshotUrl);
      const newImages = await transactionService.getImages(transactionId);
      for (const image of newImages) {
        images.push({ url: image });
      }
    }

    setCurrent({ ...emptyTransaction, images, id: transactionId });
    setCommittedImages(images);
  };

  const commit = async (data) => {
    const { id, images, ...transaction } = data ?? current;
    const uncommittedImages = images.filter(image => !!image.data_url);
    const removedImages = committedImages.filter(
      committedImage => !images.some(image => image.url === committedImage.url)
    );
    await Promise.all(uncommittedImages.map(
      image => transactionService.addImage(id, { "img": image.data_url })
    ));
    await Promise.all(removedImages.map(
      image => transactionService.removeImage(current.id, image.url.match(imageNameMatcher)[1])
    ));
    await transactionService.modify(current.id, transaction);
  };

  const modifyAndCommit = async (data) => {
    modify(data);
    await commit(data);
  };

  const reset = () => {
    setCurrent(emptyTransaction);
    setCommittedImages([]);
  };

  const modify = (data) => setCurrent(data);

  return (
    <CreateTransactionContext.Provider value={{ current, create, commit, reset, modify, modifyAndCommit }}>
      {children}
    </CreateTransactionContext.Provider>
  );
};
