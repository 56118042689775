import * as React from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import DesktopSidebar from "./DesktopSidebar.js";
import MobileSidebar from "./MobileSidebar.js";

export default function Sidebar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return isMobile ? <MobileSidebar /> : <DesktopSidebar />;
}
