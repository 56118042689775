export const TOKEN_RENEW_MS = 60 * 1000 * 5;  // 5 minutes
export const POSTI_TRACKING_URL="https://www.posti.fi/fi/seuranta#/lahetys";

export const Style = {
  Sidebar: {
    WidthPx: 240,
    LogoWidthPx: 100,
    Background: "#2E2D31",
    Color: "#E5E1E6",
  },
  BorderRadius: {
    Large: "16px",
  },
};

/*export const termsOfService = `Käyttääksesi palvelua sinun on hyväksyttävä seuraavat ehdot.

1. Palvelun kuvaus

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vitae mi sit amet velit lobortis ultrices nec eu metus. Vestibulum viverra quam dolor, non consequat est dapibus vitae. Praesent lectus eros, laoreet feugiat magna elementum, blandit porttitor mauris. Pellentesque molestie rutrum dui, quis lacinia magna hendrerit non. Integer eget sapien aliquet, gravida magna eget, pulvinar sem. Morbi in justo ut tellus tempor dictum. Nulla consequat tincidunt neque ac feugiat. Proin commodo gravida elit, non consectetur dolor.


2. Henkilötietojen käsittely

- Tunnistautuminen
- Rekisteriseloste
- Tietosuojalauseke

Nullam vel tincidunt lorem. Fusce gravida iaculis felis, vel feugiat tortor imperdiet vel. In malesuada diam lectus, at aliquet turpis accumsan cursus. Fusce aliquam vestibulum sodales. Phasellus sed est justo. In dignissim molestie placerat. Mauris molestie mauris non diam sagittis convallis quis vel ex. Sed maximus lacinia sapien. Pellentesque pharetra leo id mi dapibus fermentum.


3. Käyttäjän velvollisuudet ja vastuut

Maecenas efficitur tristique nibh, id efficitur urna mollis nec. Mauris vel volutpat ligula, ut molestie lacus. Duis fermentum libero diam. Nulla hendrerit consequat lacus, ut eleifend sem luctus id. Aliquam eleifend fringilla placerat. Donec porttitor, quam eu sodales molestie, velit quam porta nulla, vitae dictum elit elit at dolor. Pellentesque eget rhoncus magna.


4. Palvelun hinnat

Vivamus auctor lectus ut sapien tristique, ac interdum magna hendrerit. Integer id nibh et mauris pellentesque mollis eget sit amet felis. Sed nibh turpis, commodo et gravida nec, pharetra eget nulla. In vel porttitor dui, et feugiat enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque bibendum rhoncus odio. Sed volutpat, tellus id malesuada pulvinar, ex mi tempor libero, eu tincidunt ligula risus id felis. Ut dignissim leo mauris, ut cursus nibh consectetur at. Mauris pulvinar suscipit vestibulum.


5. Ikäraja

Mauris suscipit nisi nec libero vestibulum vulputate. Proin bibendum ornare consectetur. Praesent sit amet rhoncus massa, et dignissim mi. Pellentesque euismod tellus auctor metus sollicitudin, non consequat lectus pulvinar.


6. Tietosuoja

Nunc vitae egestas erat, et eleifend libero. Proin ullamcorper dui est, in tincidunt arcu venenatis ut. Quisque placerat, urna eget mattis hendrerit, augue elit elementum urna, id sodales sapien eros sit amet magna. Proin egestas dapibus orci id feugiat. Nunc nec ex tincidunt, suscipit risus vehicula, dignissim nulla. Vestibulum ut mauris scelerisque, posuere elit et, tempus dolor. Fusce eu bibendum augue, vel facilisis neque. Praesent vitae suscipit enim. Aenean sit amet justo purus.


7. Sopimusehtojen muutokset

Fusce gravida iaculis felis, vel feugiat tortor imperdiet vel. In malesuada diam lectus, at aliquet turpis accumsan cursus. Fusce aliquam vestibulum sodales. Phasellus sed est justo. In dignissim molestie placerat. Mauris molestie mauris non diam sagittis convallis quis vel ex. Sed maximus lacinia sapien. Pellentesque pharetra leo id mi dapibus fermentum.


8. Muut ehdot

Ut dignissim leo mauris, ut cursus nibh consectetur at. Mauris pulvinar suscipit vestibulum. Ut consectetur lacus non luctus blandit. Mauris suscipit nisi nec libero vestibulum vulputate. Proin bibendum ornare consectetur. Praesent sit amet rhoncus massa, et dignissim mi. Pellentesque euismod tellus auctor metus sollicitudin, non consequat lectus pulvinar.
`;*/


export const termsOfService = `MÄÄRITELMÄT 

Käyttäjällä tarkoitetaan palveluun rekisteröitynyttä ostajaa / myyjää. 
Palveluntarjoajalla tarkoitetaan turvamaksut.fi sivustoa, jolla käyttäjät voivat keskenään tehdä turvallisesti kauppoihin liittyvät rahasiirrot. 
Kaupalla tarkoitetaan käyttäjien keskenään sopiman kauppasumman siirrosta ostajan / myyjän välillä. 
Kohteella tarkoitetaan myytävää tuotetta. 


REKISTERÖITYMINEN PALVELUUN 

Palvelun käyttäminen edellyttää hyväksytysti suoritettua rekisteröitymistä. Rekisteröitymisen yhteydessä edellytämme vahvaa tunnistautumista käyttäjiltä. Palvelun tarjoaa ulkopuolinen taho (NETS).
Käyttäjän tulee antaa rekisteröitymisen yhteydessä pyydetyt tiedot ja niiden tulee olla oikeita ja aina ajantasaisia. Käyttäjän tulee olla täysi-ikäinen ja täysivaltainen.
Käyttäjällä voi olla ainoastaan yksi (1) Tunnus ja tili Palvelussa.


LISÄEHDOT

Jotkin Palvelun toiminnoista ja/tai ominaisuuksista voivat hyödyntää ja käyttää kolmansien osapuolien tarjoamia työkaluja, tuotteita ja palveluja (”Ulkopuoliset Palvelut”) liittyen esimerkiksi vahvaan tunnistautumiseen, maksunvälitykseen, postitoimituksiin tai luottotietojen tarkistukseen. Ulkopuolisia Palveluita ja niiden käyttöä voi säännellä Ulkopuolisen Palvelun tarjoajan ja/tai tuottajan (”Ulkopuolinen Palveluntarjoaja”) määrittämät erilliset käyttöehdot ja tietosuojaselosteet (”Ulkopuoliset Palveluehdot”). Voidakseen käyttää Palvelun sellaisia toimintoja, jotka hyödyntävät Ulkopuolisia Palveluja, täytyy Käyttäjän tai Vierailijan erikseen hyväksyä myös tällaiset Ulkopuoliset Palveluehdot ja sitoutua noudattamaan niitä. Edellä mainitun estämässä, hyväksymällä Sitoumuksen, rekisteröitymällä Palvelun Käyttäjäksi tai käyttämällä Palvelua Käyttäjä tai Vierailija hyväksyy myös Ulkopuoliset Palveluehdot.


1. PALVELU, KÄYTTÄJÄT, TOIMINNOT

Palvelu on Yhtiön tarjoama verkko- tai/ja mobiilisovelluksena toimiva palvelu, joka mahdollistaa sen Käyttäjille käytettyjen tavaroiden, tai vastaavien ostamiseen ja myymiseen liittyvien maksujen ja tavarantoimitusten toteuttamisen sekä mahdolliset muut kulloinkin tarjottavat toiminnot 

Yhtiö veloittaa Palvelusta sen Käyttäjiltä maksuja kulloinkin voimassa olevan Hinnaston mukaisesti. Käyttämällä Palvelua, Käyttäjä tai Vierailija hyväksyy hinnaston mukaiset veloitukset, sekä sitoutuu maksamaan ne Yhtiölle ja valtuuttaa Yhtiön veloittamaan ne Hinnastossa ja/tai Palvelussa kuvatulla tavalla.

Yhtiö ei missään tapauksessa toimi maksunvälittäjänä tai tavarantoimittajana, eikä vastaa näistä prosesseista. Palvelun käyttämiseen voi liittyä kolmansien osapuolien tarjoamia ulkopuolisia palveluita, kuten maksunvälitys- tai logistiikkapalveluita, joiden käyttöä sääntelevät kyseisten palveluntarjoajien omat ehdot. Yhtiö ei millään tapaa vastaa kolmansien osapuolten tarjoamista palveluista tai niissä mahdollisesti esiintyvistä häiriötilanteista taikka niistä aiheutuvista kiistoista tai vahingoista.

Palvelua eivät voi käyttää henkilöt, jotka harjoittavat tavaran kauppaa ammattimaisesti tai muutoin sellaisella tavalla, jota voitaisiin pitää elinkeinotoimintana.
Käyttäjätilin luojan ja haltijan on oltava vähintään 18-vuotias täysivaltainen luonnollinen henkilö, joka on Suomen kansalainen, jonka vakituinen osoite on Suomessa, jolla on pankkitili Suomen talletussuojajärjestelmään kuuluvassa pankissa, ja jolla on oikeus solmia Sitoumus Yhtiön kanssa. Henkilö voi luoda Käyttäjätilin vain itselleen eikä Käyttäjätiliä voi luoda toisen henkilön puolesta tai tämän ominaisuudessa. Henkilö voi käyttää Palvelussa vain omaa Käyttäjätiliään.
Rekisteröitymällä Palvelun Käyttäjäksi (”Rekisteröitymisprosessi”) tai käyttämällä Palvelua, Käyttäjä sitoutuu aina Yhtiön pyytäessä viipymättä toimittamaan ja täydentämään oikeat, lainmukaiset, totuudenmukaiset, tarkat, ajan tasalla olevat ja täydelliset tiedot koskien itseään, Käyttäjätiliään ja Palvelun käyttöään (”Oikeat Tiedot”). Käyttäjä sitoutuu käyttämään Oikeita Tietoja myös täyttäessään tietoja Palveluun tai sen mahdollistamiin viestintäkeinoihin. Käyttäjä sitoutuu myös varmistamaan, että tämän Käyttäjätilin tiedot ovat kaikilla hetkillä Oikeat Tiedot ja tarvittaessa viipymättä päivittämään Käyttäjätilin tiedot.

Rekisteröitymisprosessissa Käyttäjä toimittaa seuraavat tiedot

- Etunimi ja sukunimi
- Salasana
- Sähköpostiosoite
- Puhelinnumero
- Kotiosoite
- Pankkitilin numero
- Onko käyttäjä polilittisesti vaikutusvaltainen henkilö
- Varojen pääasiallinen alkuperä

Käyttäjän nimi, sähköpostiosoite ja osoite on tieto joka voi näkyä muille Käyttäjille ja/tai Vierailijoille. Myös osa tai kaikki muista edellä mainituista ja mahdollisista muista tiedoista (esimerkiksi profiilikuva), pois lukien salasana, voi näkyä muille Käyttäjille ja/tai Vierailijoille,  riippuen Käyttäjän määrittämistä asetuksista sekä Käyttäjän tekemistä valinnoista osana Palvelun käyttöä.
Yhtiö voi ajoittain edellyttää Käyttäjää vahvistamaan Käyttäjätilinsä. Vahvistaminen voi tapahtua esimerkiksi vahvalla tunnistautumisella. Vahvistaminen saattaa tapahtua myös esimerkiksi pin-koodin perusteella tai millä tahansa muulla keinolla, jonka valitseminen on yksinomaan Yhtiön päätettävissä.

Luomalla Käyttäjätilin, käyttämällä Palvelua tai hyväksymällä Sitoumuksen Käyttäjä tai Vierailija hyväksyy ja antaa Sitoumuksen.

Palvelua ei saa käyttää eikä Sitoumusta hyväksyä, mikäli Käyttäjältä tai Vierailijalta on kielletty Palvelun käyttö, Yhtiön toimesta tai minkä tahansa voimassa olevan lain perusteella.


2. KAUPANKÄYNNIN OSAPUOLET

Palvelun avulla toteutettavissa Kaupoissa osapuolina ovat Myyjä ja Ostaja. Kukin Osapuoli toteuttaa Transaktioita omaan lukuun sekä vastaa toiminnastaan ja viestinnästään itse henkilökohtaisesti.

Yhtiö ei myy, osta, anna vuokralle, ota vuokralle tai lahjoita Palvelussa esiintyviä Esineitä, eikä Yhtiö ole osapuolena Palvelun avulla toteutetuissa Transaktioissa.

Yhtiö voi osana asiakaspalveluaan pyrkiä auttamaan Käyttäjiä löytämään ratkaisuja mahdollisiin ongelmatilanteisiin antamalla toimintasuosituksia. Ellei toimintasuosituksessa viitata Käyttöehtoihin ja todeta suosituksen olevan sitova tai velvoittava, ei suositus myöskään sido tai velvoita Käyttäjää noudattamaan kyseistä suositusta. Mikäli käyttäjä kuitenkin päättää noudattaa toimintasuositusta, on kyseinen päätös täysin Käyttäjän oma ja tämän omalla vastuulla, eikä Yhtiötä tai Palvelua voida millään tapaa pitää vastuussa suosituksen noudattamisen seurauksista. 

Yhtiö ei tarjoa taloudellista, juridista tai verotuksellista neuvonantoa, eikä mitään Yhtiön kommunikaatiota taikka Palvelussa tapahtuvaa tai siihen liittyvää viestintää tai toiminnallisuutta voida pitää edellä mainittuna tai siihen rinnastettavana neuvonantona.


KAUPAN LUONNE JA OSAPUOLET 

Palveluntarjoaja ei ole kaupan osapuoli ja Palveluntarjoajan rooli rajoittuu siihen, että se tarjoaa Käyttäjille Palvelun, jonka avulla kaupan osapuolet voivat turvallisesti hoitaa maksuliikenteen tuotteen osalta. Vastuu kaupasta on kunkin kaupan osapuolilla. Palveluntarjoaja ei hyväksy mitään vastuuta kaupoista 

Kaupan hinta, kauppahinnan maksaminen sekä muut kauppaan ja Kohteeseen liittyvät ominaisuudet ja toimenpiteet ovat kunkin kaupan osapuolten vastuulla soveltuvan lainsäädännön ja Käyttäjien välisten sopimusten mukaisesti. 


3. TRANSAKTION OSAPUOLTEN VÄLISET ONGELMATILANTEET

Yhtiö ei toimi Transaktioissa osapuolena eikä siten koskaan ole Myyjä, tai ostaja. Käyttäjät toteuttavat Palvelussa Transaktiota omaan lukuunsa suoraan toisten Käyttäjien kanssa. Mahdollisissa ongelma- ja kiistatilanteissa Käyttäjät vastaavat itse asian ratkaisemisesta, noudattaen voimassa olevaa lainsäädäntöä.

Edellisen kohdan estämättä, Palvelun Käyttäjät sitoutuvat kaupankäyntiin liittyvässä ongelmatilanteessa noudattamaan tässä kohdassa kuvattua ongelmanratkaisumenettelyä (”Reklamaatioprosessi”) sekä valtuuttavat Yhtiön toteuttamaan niiden puolesta sitovasti Reklamaatioprosessin toimeenpanemiseksi tarvittavat toimet. Yhtiö ei kuitenkaan takaa tai vastaa siitä, että asiassa lopulta menetellään tai se ratkeaa Reklamaatioprosessin mukaisesti.
Myyjän ei tule koskaan postittaa Kohdetta ennen kuin Myyjä on saanut Palvelusta vahvistuksen siitä, että Ostaja on suorittanut Kohdetta koskevan maksun. Mikäli Myyjä postittaa Kohteen ennen kyseistä vahvistusta, luopuu Myyjä oikeudestaan reklamoida tai riitauttaa tilannetta, jossa Ostaja pitää Kohteen itsellään eikä suorita maksua.

Mikäli Ostaja on suorittanut Palvelun kautta maksun koskien palveluun määritellyn tuotteen osalta, eikä Myyjä määräajan sisällä postita Kohdetta Ostajalle, peruuntuu Kauppa ja Ostajalle palautetaan tämän maksama rahamäärä vähennettynä Hinnaston mukaisella transaktiomaksulla. Yhtiöllä on oikeus periä kyseinen transaktiomaksu Myyjältä, ja Myyjällä on velvollisuus suorittaa transaktiomaksu Yhtiölle. Mikäli Myyjä suorittaa transaktiomaksun Yhtiölle, Yhtiö pyrkii, kohtuullisen ajankäytön ja kustannusten rajoissa, palauttamaan maksun Ostajalle.

Mikäli Ostaja on suorittanut Palvelun kautta maksun koskien määriteltyä tuotetta ehtojen mukaisesti ja Myyjä on edellytetyn aikarajan sisällä postittanut Kohteen Ostajalle, ja minkä jälkeen Ostaja on tehnyt reklamaation Kohdetta koskien, soveltuvat Reklamaatioprosessiin seuraavat sitovat ehdot:
Myyjä ja Ostaja sitoutuvat ensisijaisesti neuvottelemaan ja sopimaan keskenään siitä, miten tilanne ratkaistaan
Ostajan ja myyjän neuvottelut päätyvät yhteen seuraavista lopputuloksista (”Ratkaisut”):

1. Myyjä hyväksyy reklamaation, jolloin Kohde ja rahat palautetaan (Kauppa peruuntuu Myyjän virheestä johtuvasta syystä)
2. Ostaja peruu reklamaation ja hyväksyy Kohteen (Kauppa toteutuu alkuperäisin ehdoin)
3. Myyjä ja Ostaja sopivat hinnanalennuksesta, jolloin Ostajan maksamasta summasta palautetaan Ostajalle sovitun alennuksen määrä, mikä vastaavasti vähennetään Myyjälle maksettavasta määrästä (Kauppa toteutuu muutetuin ehdoin)
4. Myyjä ja Ostaja eivät pääse neuvottelutulokseen, jolloin Kohde ja rahat palautetaan (Kauppa peruuntuu kiistanalaisesta syystä)

Reklamaatioprosessin kulku on seuraava:

Vaihe 1
Yhtiö vastaanottaa ilmoituksen Ostajan tekemästä reklamaatiosta ja ilmoittaa asian myyjälle. 

Vaihe 2
Myyjä ja ostaja neuvottelut asiasta ja pyrkivät löytämään ratkaisun sopimisen avulla. Sovitteluaika on 2 vuorokautta ja aika lasketaan alkaneeksi siinä vaiheessa, kun myyjälle on ilmoitettu reklamaatiosta. Myyjä ja Ostaja voivat missä tahansa vaiheessa neuvotteluaikaa sopia yksimielisestä ratkaisusta asiaan (Ratkaisut 1-3), jonka he vahvistavat Yhtiön asiakaspalvelulle kirjallisesti, minkä jälkeen yhtiö toimii kaupan osapuolien sopimuksen mukaan seuraavasti:

Ratkaisu 1
-Ostaja palauttaa tuotteen myyjälle ja kun myyjä on vastaanottanut palautetun tuotteen, niin kauppasumma palautetaan ostajalle. Veloitamme hinnaston mukaisen komission palautetusta kauppasummasta. 

Ratkaisu 2
-Kauppa toteutetaan normaalisti

Ratkaisu 3
-Kauppa toteutetaan muutetuin ehdoin, ostajalle palautetaan osa kauppasummasta ja myyjälle tilitetään sovittu osuus. Veloitamme tässä yhteydessä myyjän osuudesta hinnaston mukaisen maksun lisäksi 1,99€ lisäkulun. 

Ratkaisu 4
Mikäli Vaiheessa 2 ei saavuteta yksimielistä ratkaisua, tai yhtiölle ei ilmoiteta asiasta mitään sopimusajan puitteissa. Toimintatapa on seuraava: ilmoitamme kaupan osapuolille kaupan peruuntumisesta, ostaja on velvoitettu lähettämään kaupan kohteena ollut esine myyjälle. Lähetyskuluista vastaa ostaja, kun myyjä on noutanut tuotteen ja kuitannut tuotteen noudetuksi, niin palautamme ostajalle kauppasumman. Veloitamme kauppasummasta hinnaston mukaisen maksun. 
Mikäli palautettu Kohde ei vastaa tämän alun perin Ostajalle lähettämää Kohdetta, sitoutuvat Myyjä ja Ostajan jatkamaan neuvotteluja kiistatilanteen ratkaisemisesta suoraan keskenään sekä olemaan vetoamatta Yhtiöön asian ratkaisemiseksi                                     


TOIMINNOT ERI TILANTEISSA:

1.	Ostaja ei maksa tuotetta
Mikäli ostaja ei maksa tuotetta 48 tunnin kuluessa siitä kun molemmat osapuolet ovat rekisteröityneet käyttäjiksi,  niin kauppa perutaan. 	

2. Ostaja ei nouda tuotetta ja paketti palautuu lähettäjälle
Tilitämme kauppasummasta myyjälle aiheutuneet kustannukset (2xlähetyskustannus), loppukauppasumma palautetaan ostajalle ja tästä veloitetaan miniveloitus 1,99€.


KÄYTTÄJÄN VELVOLLISUUDET

Käyttäjä sitoutuu noudattamaan näitä Käyttöehtoja, Sääntöjä, voimassaolevia lakeja, normeja ja viranomaismääräyksiä käyttäessään Palvelua. Käyttäjä ei saa käyttää Palvelua hyvän tavan vastaisesti. Käyttäjä käyttää Palvelua omalla kustannuksellaan ja vastuullaan. 

Käyttäjä ei saa luovuttaa Tunnuksiaan kolmansille osapuolille. Käyttäjän tulee ilmoittaa välittömästi Palveluntarjoajalle Tunnustensa joutuessa ulkopuolisen tietoon. Käyttäjä vastaa Tunnuksillaan tapahtuneesta Palvelun käytöstä, kunnes Käyttäjä on ilmoittanut Palveluntarjoajalle kirjallisesti (esim. sähköpostitse), että Tunnukset tulee sulkea. Käyttäjä vastaa myös Tunnustensa ajan tasalla pitämisestä. 

Käyttäjä sitoutuu käyttämään Palvelua siten, että Käyttäjä ei toimillaan vaikeuta tai haittaa Palvelun tuottamista ja ylläpitoa. Käyttäjä sitoutuu tallentamaan Palveluun ainoastaan sellaista Sisältöä, joka on lakien, normien ja hyvän tavan mukaista. 


4. PALVELUNTARJOAJAN OIKEUDET JA VELVOLLISUUDET
 
Palveluntarjoaja pyrkii kohtuullisin toimin tarjoamaan Palvelun 24/7. Palvelun käyttökatkot ovat mahdollisia muun muassa huollon, suuren käyttäjämäärän, teknisen häiriön tai muun syyn vuoksi. Palveluntarjoaja pyrkii informoimaan käyttökatkoista etukäteen, mutta Palveluntarjoaja ei vastaa mahdollisista käyttökatkojen aiheuttamista tietojen katoamisista tai Palvelun keskeytyksistä eikä niihin liittyvistä vahingoista. 

Palveluntarjoaja pyrkii tarjoamaan korkeatasoisen Palvelun, mutta ei takaa Palvelun tai Sisältöjen virheettömyyttä, oikeellisuutta tai toimintavarmuutta, eikä hyväksy muuta vastuuta Sisällöistä. 

Palveluntarjoajalla on oikeus muuttaa ja poistaa osia tai toiminnallisuuksia Palvelusta sekä lisätä niitä, taikka lopettaa Palvelun tarjoaminen ja ylläpitäminen osittain tai kokonaisuudessaan. 
Palveluntarjoaja ei vastaa Käyttäjien Välisissä Kaupoissa annetuista tiedoista (ml. Käyttäjien antamat Ilmoittajan tai esineen omistajan tiedot), eikä siitä onko käyttäjä oikeutta myydä Ilmoituksen mukaista esinettä. Palveluntarjoaja ei vastaa Palvelussa mahdollisesti olevista linkeistä kolmansien ylläpitämille sivustoille. 

Palveluntarjoajalla on myös oikeus tehdä tai vaatia Ilmoittajia tekemään muutoksia Ilmoituksiin, asettaa Käyttäjille Palvelun käyttöön liittyviä sääntöjä ja antaa Käyttäjille Palvelun käyttöön liittyviä sitovia ohjeita. 

Palveluntarjoaja pidättää oikeuden tarkistaa kaikki julkaistut kaupat sekä julkaisun aikana ja että sen jälkeen. Kaupat, jotka rikkovat palveluehtoja voidaan muuttaa tai poistaa ilman erillistä ilmoitusta. Palveluntarjoajalle on oikeus estää maksujen välittäminen ja poistaa käyttäjien tilit, mikäli palveluntarjoajalle syntyy perusteltu epäilys, siitä että maksuvälitystä käytetään lainvastaiseen maksujen välitykseen. 

Palveluntarjoajalla on oikeus estää Käyttäjän kirjautuminen Palveluun, jos tämä käyttää Palvelua näiden Käyttöehtojen vastaisesti, samoin kuin muusta perustellusta syystä. 
Työtaistelu, tulipalo, luonnonkatastrofi, tietoliikennehäiriö, viranomaisen määräys, sota maantieteellisestä sijainnista riippumatta, tai Palveluntarjoajasta riippumaton ylivoimainen este vapauttaa Palveluntarjoajan noudattamasta näiden Käyttöehtojen velvoitteita esteen keston ja kohtuullisen ajan esteen poistumisen jälkeen, kunnes velvoitteiden täyttämisen edellytykset pystytään palauttamaan. 


5. VASTUUNRAJOITUS 

Ellei pakottavasta lainsäädännöstä muuta johdu, Palveluntarjoaja ei vastaa eikä ole korvausvelvollinen mistään: i) välillisistä tai välittömistä vahingoista; tai ii) kuluista tai menetyksistä, jotka liittyvät Palveluun tai sen käyttöön (mukaan lukien tietojen mahdollinen katoaminen ja niiden uudelleen luomisen kustannukset), Palvelussa ilmenevään Sisältöön tai Kohteiden ostoon ja myyntiin. 

Myyjä on täysin vastuussa tuotteesta, ilmoituksen sisällöstä ja tuotteen toimituksesta ostajan kanssa tehtyjen sopimusten mukaisesti. 

Rajoittamatta edellä sanottua, Palveluntarjoaja ei vastaa mistään myymäänsä Kohteeseen liittyvästä virheestä 
Palveluntarjoaja ei hyväksy mitään vastuuta liittyen Käyttäjien Välisiin Kauppoihin tai mahdollisiin Muihin Ilmoituksiin liittyviin kauppoihin. 


6. TIETOSUOJA 

Palveluntarjoaja kerää ja käsittelee Palvelun yhteydessä Käyttäjien henkilötietoja. Palveluntarjoajan henkilötietojen käsittelyyn liittyvät käytännöt on kuvattu Palvelun tietosuojaselosteessa, joka on saatavilla osoitteessa turvamaksut.fi. Käyttäjä hyväksyy, että tietosuojaseloste on osa Palvelun käyttöön liittyvää sopimuskokonaisuutta. 


7. KÄYTTÖEHTOJEN MUUTTAMINEN 

Palveluntarjoajalla on oikeus muuttaa näitä Käyttöehtoja ilmoittamalla muutoksesta Käyttäjälle sähköpostitse tai Palvelun välityksellä. Muutokset tulevat voimaan seitsemän (7) päivän kuluttua muutosilmoituksesta. Käyttäjä hyväksyy muutokset jatkamalla Palvelun käyttöä. 


8. VOIMASSAOLO JA PÄÄTTYMINEN 

Käyttäjän ja Palveluntarjoajan välinen sopimus tulee voimaan Käyttäjän hyväksyessä Käyttöehdot ja Palveluntarjoajan myöntäessä Käyttäjälle käyttöoikeuden Palveluun. Sopimus on voimassa toistaiseksi. Käyttäjä ei vapaudu Palvelun käytön päättämisellä vastuistaan, jotka hänelle ovat syntyneet Palvelun käytön aikana. 


9. SIIRTÄMINEN 

Palveluntarjoajalla on oikeus siirtää Palvelu (ja siihen liittyvät Sisällöt, käyttäjätiedot ja sopimukset) kokonaan tai osittain konserninsa sisäisesti sekä kolmannelle osapuolelle yritysjärjestelyn tai toimintojensa ulkoistuksen yhteydessä. Käyttäjällä ei ole oikeutta siirtää sille myönnettyä Palvelun käyttöoikeutta tai muita oikeuksiaan ja velvoitteitaan Palveluun liittyen. Käyttöoikeus on henkilökohtainen. 


10. KULUTTAJANSUOJALAIN MUKAISET TIEDOT
 
Turvamaksut.fi on Safe Payment Group Oy:n (Y-tunnus: 3275669-7) ylläpitämä turvallisen maksamisen verkkoalusta. Safe Payment Group Oy:n käynti- ja postiosoite on Haapalankatu 1 A, 15240 Lahti, Suomi. Safe Payment Group Oy:n yhteystiedot löytyvät täältä 
Kuluttajalla on oikeus saattaa sen ja elinkeinonharjoittajan välinen riita kuluttajariitalautakunnan ratkaistavaksi. Lisätietoja on saatavilla osoitteessa https://www.kuluttajariita.fi/fi/index/ostajalle_1.html. 


11. SOVELLETTAVA LAKI 

Näihin Käyttöehtoihin sovelletaan Suomen lakia. Käyttöehtoihin liittyvät erimielisyydet ratkaistaan ensiasteena Helsingin käräjäoikeudessa. Kuluttaja-asiakkaalla on aina oikeus saattaa asiansa käsiteltäväksi myös oman kotipaikkakunnan käräjäoikeudessa.

`;