import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import InputLabel from "@mui/material/InputLabel";
import { StyledFormHelperText } from "./form/InputTextField";

export default function SelectOption({ options, selectedOption, setSelectedOption, fullWidth, label, error }) {
  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <InputLabel shrink position="top">
        {label} 
      </InputLabel>
      <ButtonGroup disableElevation variant="outlined" fullWidth={fullWidth} color={error ? "error" : "primary"}>
        {options.map(option => (
          <Button
            key={option.key}
            onClick={() => handleSelect(option)}
            sx={{ borderRadius: "16px" }}
            variant={option.key === selectedOption?.key ? "contained" : "outlined"}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
      { error && <StyledFormHelperText error style={{paddingLeft: 16}}>{error}</StyledFormHelperText> }
    </>
  );
}