import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import Grid from "@mui/material/Grid";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import InputTextField from "../common/form/InputTextField";
import DropdownOption from "../common/form/DropdownOption";
import { useValidation } from "../../hooks/useValidation";
import { validation as signupValidation } from "../../shared/model/signup";
import VerticalContentGrid from "../common/VerticalContentGrid";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { formatIban } from "../../util/iban";
import { Radio, RadioGroup, FormControl, FormControlLabel } from "@mui/material";

const labels = {
  firstNames: "Etunimet",
  lastName: "Sukunimi",
  email: "Sähköpostiosoite",
  phoneNumber: "Puhelinnumero",
  address: "Osoite",
  zipcode: "Postinumero",
  city: "Postitoimipaikka",
  bankAccount: "Tilinumero (IBAN)",
  moneyOrigin: "Varojen pääasiallinen alkuperä",
  moneyOriginTooltip: "Joudumme kysymään näitä kysymyksiä rahanpesuun liittyvän lainsäädännön johdosta.",
  moneyOriginOptions: [
    "Palkka",
    "Eläke", 
    "Säästöt", 
    "Sosiaalietuudet", 
    "Pääomatulot", 
    "Perintö", 
  ],
  password: "Salasana",
  passwordConfirmation: "Vahvista salasana",
  pep: "PEP selvitys *",
  pepDescription: "Oletko kotimaassa tai ulkomailla poliittisesti vaikutusvaltaisessa asemassa (poliittisesti vaikutusvaltainen henkilö, PEP) tai tällaisen henkilön perheenjäsen tai läheinen yhtiökumppani?",
  pepDecline: "Valitettavasti tässä vaiheessa emme pysty vastaanottamaan poliittisesti vaikutusvaltaisten henkilöiden rekisteröitymistä palveluumme.",
};

export default function SignUpForm({ firstNames, lastName, email, onSubmit, loading }) {
  const passwordConfirmationRef = useRef(null);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(null);
  const [pep, setPep] = useState("no");
  const [moneyOrigin, setMoneyOrigin] = useState("");
  const moneyOriginRef = useRef(null);
  const apiErrorContext = useApiErrorContext();
  const validation = {
    email: useValidation(signupValidation.email),
    phoneNumber: useValidation(signupValidation.phoneNumber),
    address: useValidation(signupValidation.address),
    zipcode: useValidation(signupValidation.zipcode),
    city: useValidation(signupValidation.city),
    bankAccount: useValidation(signupValidation.bankAccount),
    password: useValidation(signupValidation.password),
    moneyOrigin: useValidation(signupValidation.moneyOrigin),
  };

  const submit = (event) => {
    event.preventDefault();

    const errors = Object.entries(validation)
      .map(([key, validation]) => ([ key, validation.validate() ]))
      .filter(([key, error]) => error !== null);

    if (errors.length > 0) {
      const error = errors.map(([key, error]) => `- ${labels[key] ?? "N/A"}: ${error}`).join("\n");
      apiErrorContext.addError(`Tiedoissa on virheitä:\n\n${error}`);
      return;
    }

    if (validatePasswordConfirmation() !== null) {
      apiErrorContext.addError("Salasanojen on vastattava toisiaan");
      return;
    }

    const data = Object.entries(validation).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value.inputRef.current.value,
    }), {});
    onSubmit(data);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "sign_up",
      });
    }
  };

  const emailInputProps = {
    ...(email ? {
      endAdornment: <CheckIcon />,
      readOnly: true,
    } : {
      autoFocus: true,
    })
  };

  const validatePasswordConfirmation = () => {
    const error = 
    passwordConfirmationRef.current?.value !== validation.password.inputRef.current?.value
      ? "Salasanojen on vastattava toisiaan"
      : null;
    
    setPasswordConfirmationError(error);
    return error;
  };

  const handlePep = (event) => {
    if (event.target.value === "yes") {
      apiErrorContext.addError(`${labels.pepDecline}`);
    }
    setPep(event.target.value);
  };

  useEffect(() => {
    if (moneyOriginRef.current) {
      validation.moneyOrigin.validate();
    }
  }, [moneyOriginRef, validation.moneyOrigin]);

  
  useEffect(() => {
    const inputElement = passwordConfirmationRef.current;
    if (!inputElement) {
      return;
    }

    const onInput = () => {
      validatePasswordConfirmation();
    };

    const onBlur = () => {
      validatePasswordConfirmation();
    };

    passwordConfirmationRef.current.addEventListener("input", onInput);
    passwordConfirmationRef.current.addEventListener("blur", onBlur);

    return () => {
      inputElement.removeEventListener("input", onInput);
      inputElement.removeEventListener("blur", onBlur);
    };
  }, [passwordConfirmationRef]);

  useEffect(() => {
    const inputElement = validation.bankAccount.inputRef.current;
    if (!inputElement) {
      return;
    }

    const updateValue = () => {
      inputElement.value = formatIban(inputElement.value);
    };
    updateValue();
    inputElement.addEventListener("input", updateValue);
    return () => {
      inputElement.removeEventListener("input", updateValue);
    };
  }, [validation.bankAccount.inputRef]);

  return (
    <form onSubmit={submit} style={{ height: "fit-content" }}>
      <VerticalContentGrid>
        <Grid item container justifyContent="center" alignItems="center" gap={2} mb={2}>
          <AccountCircleOutlinedIcon fontSize="large" />
          <Typography variant="h4">Rekisteröidy</Typography>
        </Grid>
        <InputTextField label={labels.firstNames} defaultValue={firstNames} readOnly endAdornment={<CheckIcon />} />
        <InputTextField label={labels.lastName} defaultValue={lastName} readOnly endAdornment={<CheckIcon />} />
        <InputTextField
          required
          label={labels.email}
          type="email"
          defaultValue={email}
          {...validation.email}
          {...emailInputProps}
        />
        <InputTextField required label={labels.password} type="password" {...validation.password} />
        <InputTextField
          required
          label={labels.passwordConfirmation}
          inputRef={passwordConfirmationRef}
          type="password"
          error={!!passwordConfirmationError}
          errorText={passwordConfirmationError}
        />
        <InputTextField required label={labels.phoneNumber} type="tel" {...validation.phoneNumber} />
        <InputTextField required label={labels.address} {...validation.address} />
        <InputTextField required label={labels.zipcode} {...validation.zipcode} />
        <InputTextField required label={labels.city} {...validation.city} />
        <InputTextField required label={labels.bankAccount} {...validation.bankAccount} />
        <DropdownOption 
          label={labels.moneyOrigin}
          options={labels.moneyOriginOptions}
          selectedOption={moneyOrigin}
          tooltip={labels.moneyOriginTooltip}
          inputRef={moneyOriginRef}
          onChange={setMoneyOrigin}
          onOptionChange={(val) => moneyOriginRef.current = val.current}
          required
          {...validation.moneyOrigin}
        />
        <FormControl>
          <Typography variant="subtitle1" style={{ marginBottom: "5px"}}>{labels.pep}</Typography>
          <Typography variant="body2">
            {labels.pepDescription}
          </Typography>
          <RadioGroup 
            row
            value={pep}
            onChange={handlePep}
            style={{ marginBottom: "15px", marginTop: "5px"}}
          >
            <FormControlLabel value="no" control={<Radio size="small" />} 
              label="Ei" style={{ paddingRight: "25px" }} />
            <FormControlLabel value="yes" control={<Radio size="small" />} 
              label="Kyllä" />
          </RadioGroup>
        </FormControl>
        <Grid item width="100%">
          <Button type="submit" fullWidth variant="contained" color="button" 
            onClick={submit} disabled={loading || pep === "yes"} style={{ marginBottom: "30px"}}>
            Rekisteröidy
          </Button>
        </Grid>
      </VerticalContentGrid>
    </form>
  );
}
