import React, { useState, useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useLoadingContext, withLoading } from "../../contexts/LoadingContext";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useUserService } from "../../hooks/useUserService";
import useToken from "../../hooks/useToken.js";
import { eIdentDataStorageKey, invitationDataStorageKey } from "../../util/eident";
import { getJwtPayLoad } from "../../util/jwt";
import SignUpForm from "./SignUpForm";
import VerticalContentGrid from "../common/VerticalContentGrid";


export default function SignUp() {
  const userService = useUserService();
  const navigate = useNavigate();
  const [prefillDataError, setPrefillDataError] = useState(false);
  const [prefillData, setPrefillData] = useState(null);
  const [invitationData, setInvitationData] = useState(null);
  const [, setToken] = useToken();
  const [noInvitationSuccess, setNoInvitationSuccess] = useState(false);
  const [formData, setFormData] = useState(null);
  const [emailResent, setEmailResent] = useState(false);
  const loadingContext = useLoadingContext();
  const loading = loadingContext.isLoading.length > 0;

  useEffect(() => {
    const invitationData = JSON.parse(sessionStorage.getItem(invitationDataStorageKey));
    const eIdentJWT = sessionStorage.getItem(eIdentDataStorageKey);
    const eIdentClaims = getJwtPayLoad(eIdentJWT);
    const requiredClaims = ["amr", "family_name", "given_name", "fi_ssn"];

    if (!requiredClaims.every((property) => Object.keys(eIdentClaims).includes(property))) {
      setPrefillDataError(true);
      return;
    }

    setInvitationData(invitationData);
    setPrefillData({
      email: invitationData?.email,
      firstNames: eIdentClaims.given_name,
      lastName: eIdentClaims.family_name,
      jwt: eIdentJWT,
    });
  }, []);

  const onSubmitForm = async (data) => withLoading(loadingContext, async () => {
    if (invitationData) {
      setToken(
        await userService.signup({
          ...data,
          eIdentJWT: prefillData.jwt,
          invitationCode: invitationData.code
        })
      );
      navigate("/homepage");
    } else {
      await userService.signup({
        ...data,
        eIdentJWT: prefillData.jwt
      });
      setNoInvitationSuccess(true);
      setFormData(data);
    }
  });

  const resendEmail = async () => {
    const data = {"email": formData.email};
    await userService.resendVerificationEmail(data);
    setEmailResent(true);
  };

  return (
    <VerticalContentGrid gap={2}>
      {prefillDataError || !prefillData ? (
        <Grid item>
          <MuiAlert variant="filled" severity="error">
            Tunnistautumistietojen lataaminen epäonnistui
          </MuiAlert>
        </Grid>
      ) : (
        noInvitationSuccess ? (
          <>
            <Grid item>
              <Typography variant="h4">
                Vahvistuslinkki on lähetetty sähköpostiisi
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
              Huom! Viesti saattaa mennä joissain sähköposteissa roskapostiin, joten tarkistathan myös sieltä.
              </Typography>
            </Grid>
            <Grid item width="100%">
              <Button
                color="button"
                variant="contained"
                onClick={resendEmail}
                disabled={emailResent}
                fullWidth
              >
                Lähetä linkki uudestaan
              </Button>
            </Grid>
            <Grid item container width="100%" justifyContent="end">
              <Button onClick={() => navigate("/login")}>
                Kirjaudu sisään
              </Button>
            </Grid>
          </>
        ) : (
          <SignUpForm
            email={prefillData.email}
            firstNames={prefillData.firstNames}
            lastName={prefillData.lastName} 
            onSubmit={onSubmitForm}
            loading={loading}
          />
        ))}
    </VerticalContentGrid>
  );
}

