module.exports = {
  USER_NAME_MAX_LEN: 100,
  USER_NATIONALITY_MAX_LEN: 3,
  USER_EMAIL_MAX_LEN: 200,
  USER_PHONE_MIN_LEN: 6,
  USER_PHONE_MAX_LEN: 20,
  USER_BANK_MAX_LEN: 40,
  USER_PASSWD_MAX_LEN: 512,
  USER_PASSWD_MIN_LEN: 8,
  USER_PASSWD_HASH_MAX_LEN: 512,
  USER_SSN_MAX_LEN: 11,
  USER_ADDRESS_MAX_LEN: 200,
  USER_CITY_MAX_LEN: 100,

  TX_ITEM_DESC_MAX_LEN: 2000,
  TX_ITEM_NAME_MAX_LEN: 200,
  TX_ITEM_MIN_PRICE: 5,
  TX_ITEM_MAX_PRICE: 500000,

  URL_MAX_LEN: 500,

  TRACKING_ID_MAX_LEN: 30,
};
