import {useEffect} from "react";
import { useUserContext } from "../../contexts/UserContext";
import useToken from "../../hooks/useToken";
import { useNavigate } from "react-router-dom";

export default function LogoutView() {
  const [,setToken] = useToken();
  const { setUser } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    setToken(null);
    setUser(null);
    navigate("/login");
  }, [setToken, setUser, navigate]);
  
  return null;
}
