import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddIcon from "@mui/icons-material/Add";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SecurityIcon from "@mui/icons-material/Security";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Style } from "../../config";
import SafePaymentLogo from "./MaksuTurva-cropped.svg";

function ActionButton({ key, label, url, Icon }) {
  const location = useLocation();
  const theme = useTheme();
  const color = Style.Sidebar.Color;
  const backgroundColor = location.pathname.startsWith(url)
    ? theme.palette.primary.main :
    Style.Sidebar.Background;

  return (
    <ListItem key={key} disablePadding sx={{ backgroundColor, color }}>
      <ListItemButton component={Link} to={url}>
        <ListItemIcon>
          <Icon sx={{ color }} />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
}

function SidebarSafePaymentLogo() {
  return (
    <Box sx={{ height: 180, width: Style.Sidebar.WidthPx, mt: 7 }}>
      <Grid container spacing={0} alignItems="center" direction="column" justifyContent="center">
        <Grid item>
          <img
            src={SafePaymentLogo}
            height={Style.Sidebar.LogoWidthPx}
            width={Style.Sidebar.LogoWidthPx}
            alt="Turvamaksut logo"
          />
        </Grid>
        <Grid item>
          <Typography variant="h6">Turvamaksut</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function DesktopSidebar() {
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { md: "block", xs: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: Style.Sidebar.WidthPx,
          background: Style.Sidebar.Background,
          typography: { color: Style.Sidebar.Color },
          /**borderTopRightRadius: Style.BorderRadius.Large,
          borderBottomRightRadius: Style.BorderRadius.Large,**/
          border: "none",
        },
      }}
      anchor="left"
      open
    >
      <SidebarSafePaymentLogo />
      <List>
        <ActionButton label="Kotisivu" url="/homepage" Icon={HomeIcon} />
        <ActionButton label="Uusi kauppa" url="/create-transaction" Icon={AddIcon} />
        <ActionButton label="Ennakkomaksu" url="/create-prepayment" Icon={AlarmOnIcon} />
        <ActionButton label="Kaupat" url="/transactions" Icon={ReceiptLongIcon} />
        <ActionButton label="Turvallisuusohjeet" url="/safety-advice" Icon={SecurityIcon} />
      </List>
      <List sx={{ mt: "auto" }}>
        <ActionButton label="Tilin asetukset" url="/user-settings" Icon={AccountCircleOutlinedIcon} />
        <ActionButton label="Kirjaudu ulos" url="/logout" Icon={LogoutIcon} />
      </List>
    </Drawer>
  );
}

