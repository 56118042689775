import React, { useState } from "react";
import {
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import ImageUploading from "react-images-uploading";
import { Style } from "../../config";
import { useImagePopupContext } from "../../contexts/ImagePopupContext";

function ImageNavigationControls({ activeStep, handleNext, handleBack, images, editable }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const maxIdx = editable ? images.length : images.length - 1;

  return (
    <>
      {!isMobile && activeStep > 0 &&
        <IconButton
          style={{ color: "white", mixBlendMode: "exclusion" }}
          sx={{ position: "absolute", top: "45%", left: 10 }}
          onClick={handleBack}
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
      }
      {!isMobile && activeStep < maxIdx &&
        <IconButton
          style={{ color: "white", mixBlendMode: "exclusion" }}
          sx={{ position: "absolute", top: "45%", right: 10 }}
          onClick={handleNext}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      }
    </>
  );
}

function ImageControls({ images, activeStep, onImageUpload, onImageRemove }) {
  const image = images[activeStep];

  return (image && activeStep > 0) ? (
    <Button
      variant="contained"
      color="blackButton"
      sx={{ height: 64, borderRadius: "50%", position: "absolute", bottom: 10, right: 10 }}
      onClick={() => onImageRemove(activeStep)}
    >
      <DeleteOutlineIcon />

    </Button>
  ) : (
    (activeStep > 0) && (
      <>
        <ImageOutlinedIcon
          color="greyBackground"
          fontSize="large"
          sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        />
        <Button
          variant="contained"
          color="button"
          sx={{ height: 64, borderRadius: "50%", position: "absolute", bottom: 10, right: 10 }}
          onClick={onImageUpload}
        >
          <AddPhotoAlternateOutlinedIcon />
        </Button>
      </>
    )
  );
}

export default function ManageProductImages({ images, onImagesChange, sx, editable = true }) {
  const [activeStep, setActiveStep] = useState(0);
  const imagePopupContext = useImagePopupContext();
  const maxNumber = 6;

  const onChange = (imageList) => {
    onImagesChange?.(imageList);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => {
      return images.length > 0 && images.length - prevStep > 0 ? prevStep + 1 : prevStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep === 0 ? prevStep : prevStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // Append null to images array to allow swiping on the last image
  const visibleImages = editable ? [...images, null] : images;
  const steps = editable ? images.length + 1 : images.length;

  const combinedSx = {
    width: { md: 450, xs: "90vw" },
    height: { md: 281, xs: "25vh" },
    borderRadius: Style.BorderRadius.Large,
    ...sx,
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid container item spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <Box
            sx={{
              backgroundColor: "blackBackground.main",
              ...combinedSx,
              position: "relative",
            }}
          >
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              acceptType={["jpg", "jpeg", "png"]}
              dataURLKey="data_url"
            >
              {({ onImageUpload, onImageRemove }) => (
                <>
                  <SwipeableViews
                    enableMouseEvents
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                  >
                    {visibleImages.map((image, index) => (
                      <Box
                        key={index}
                        sx={{ ...combinedSx, display: "flex", overflow: "hidden", alignItems: "center" }}
                      >
                        {image && <Box
                          component="img"
                          sx={{
                            maxWidth: combinedSx.width,
                            maxHeight: combinedSx.height,
                            display: "block",
                            width: "auto",
                            height: "auto",
                            margin: "auto",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                          src={image.data_url || image.url}
                          alt=""
                          onClick={() => imagePopupContext.openPopup(image.data_url || image.url)}
                        />}
                      </Box>
                    ))}
                  </SwipeableViews>
                  {editable && (
                    <ImageControls
                      images={images}
                      activeStep={activeStep}
                      onImageUpload={onImageUpload}
                      onImageRemove={onImageRemove}
                    />
                  )}
                  <ImageNavigationControls
                    images={images}
                    editable={editable}
                    activeStep={activeStep}
                    handleNext={handleNext}
                    handleBack={handleBack}
                  />
                </>
              )}
            </ImageUploading>

          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <MobileStepper
          steps={steps}
          position="static"
          activeStep={activeStep}
        />
      </Grid>
    </Grid>
  );
}
