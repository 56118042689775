import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import InputTextField from "../common/form/InputTextField";
import VerticalContentGrid from "../common/VerticalContentGrid";
import { Link } from "react-router-dom";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { useUserContext } from "../../contexts/UserContext";
import { useUserService } from "../../hooks/useUserService.js";
import { useValidation } from "../../hooks/useValidation";
import { validation as signupValidation } from "../../shared/model/signup";
import MailLockIcon from "@mui/icons-material/MailLock";
import CircularProgress from "@mui/material/CircularProgress";
import useToken from "../../hooks/useToken";

const labels = {
  email: "Uusi sähköpostiosoite"
};

export default function ChangeEmail() {
  const { user, setUser } = useUserContext();
  const userService = useUserService();
  const apiErrorContext = useApiErrorContext();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [, setToken] = useToken();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validation = {
    email: useValidation(signupValidation.email, loading),
  };

  const submit = async (event) => {
    event.preventDefault();

    const errors = Object.entries(validation)
      .map(([key, validation]) => ([key, validation.validate()]))
      .filter(([key, error]) => error !== null);

    if (errors.length > 0) {
      const error = errors.map(([key, error]) => `- ${labels[key] ?? "N/A"}: ${error}`).join("\n");
      apiErrorContext.addError(`Tiedoissa on virheitä:\n\n${error}`);
      return;
    }

    try {
      setLoading(true);

      try {
        const authData = {
          password: password
        };
        await userService.reAuthenticate(user.id, authData);
      } catch(e) {
        apiErrorContext.addError("Salasana ei täsmää. Yritä uudelleen.");
        setLoading(false);
        return;
      }
      
      const data = {
        email: validation.email.inputRef.current.value
      };

      if (user.email !== data.email) {
        await userService.handleEmailChange(user.id, data);
        setSuccess(true);
        setToken(null);
        setUser(null);
      } else {
        apiErrorContext.addError(`Sähköpostiosoite on sama kuin nykyinen`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submit} style={{ height: "fit-content" }}>
      <VerticalContentGrid>
        <Grid item container justifyContent="center" alignItems="center" gap={2}>
          <MailLockIcon fontSize="large" />
          <Typography variant="h4">Sähköpostiosoitteen muutos</Typography>
        </Grid>
        {success ? (
          <>
            <Grid item container>
              <Grid item>
                <Typography variant="body1" paddingBottom="16px">
                  Vahvistuslinkki on lähetetty sähköpostiisi <strong>{email}</strong>.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Muistathan katsoa myös roskapostikansion, mikäli viesti ei näy saapuneissa.
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="end">
              <Button color="button" component={Link} to="/login">
                Takaisin kirjautumissivulle
              </Button>
            </Grid>
          </>
        ) : user && (
          <>
            <Grid item width="100%">
              <InputTextField
                label={labels.email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                {...validation.email}
                autoFocus
                //autoComplete="off"
              />
            </Grid>
            <Grid item width="100%">
              <InputTextField
                label="Nykyinen salasana"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                //autoComplete="off"
              />
            </Grid>
            <Grid item width="100%">
              <Button fullWidth variant="contained" type="submit" disabled={loading}>
                {!loading ? "Jatka" : <CircularProgress size={24} color="inherit" />}
              </Button>
            </Grid>
          </>
        )}
        {!user && !success && (
          <>
            <Grid item container>
              <Typography variant="body1">
                Sinun täytyy olla kirjautuneena sisään muuttaaksesi sähköpostiosoitetta.
              </Typography>
            </Grid>
            <Grid item container justifyContent="end">
              <Button color="button" component={Link} to="/login">
                Takaisin kirjautumissivulle
              </Button>
            </Grid>
          </>
        )}
      </VerticalContentGrid>
    </form>
  );
}