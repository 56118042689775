import AddSharpIcon from "@mui/icons-material/AddSharp";
import { Checkbox, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { CreateTransactionContext as CreatePrepaymentContext } from "../../contexts/CreateTransactionContext";
import { useLoadingContext, withLoading } from "../../contexts/LoadingContext";
import { useValidation } from "../../hooks/useValidation";
import Prepayment from "../../shared/model/prepayment";
import InputTextField from "../common/form/InputTextField";
import VerticalContentGrid from "../common/VerticalContentGrid";

const labels = {
  link: "Linkki myynti-ilmoitukseen",
  // MUUTA INSTRUCTIONS TEKSTI SLACKISTA
  instructions: "Kopioi myynti-ilmoituksen osoite selaimesi osoiteriviltä tapahtuman tekemistä varten.",
  terms: "Ymmärrän, että turvamaksut.fi ei ole kaupan osapuoli ja vastaa ainoastaan rahaliikenteen hoitamisesta.",
};

export default function CreatePrepayment() {
  const [addLink, setAddLink] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const navigate = useNavigate();
  const apiErrorContext = useApiErrorContext();
  const createPrepaymentContext = useContext(CreatePrepaymentContext);
  const loadingContext = useLoadingContext();
  const loading = loadingContext.isLoading.length > 0;

  const handleCheckboxChange = (event) => {
    setIsTermsChecked(event.target.checked);
  };

  const validation = {
    link: useValidation(Prepayment.validation.link),
  };

  const submit = async (event) => withLoading(loadingContext, async () => {
    event.preventDefault();

    const errors = Object.entries(validation)
      .map(([key, validation]) => ([key, validation.validate()]))
      .filter(([key, error]) => error !== null);

    if (errors.length > 0) {
      const error = errors.map(([key, error]) => `- ${labels[key] ?? "N/A"}: ${error}`).join("\n");
      apiErrorContext.addError(`Tiedoissa on virheitä:\n\n${error}`);
      return;
    }

    const link = validation.link.inputRef.current.value;
    await createPrepaymentContext.create("ostaja", link);
    navigate("/create-prepayment/create-manually");
  });

  useEffect(() => {
    createPrepaymentContext.reset();
  }, []);

  return (
    <form onSubmit={submit} style={{ height: "fit-content" }}>
      <VerticalContentGrid>
        <Grid item container justifyContent="center" alignItems="center" gap={2} paddingBottom={4}>
          <AddSharpIcon fontSize="large" />
          <Typography variant="h4">Uusi ennakkomaksu</Typography>
        </Grid>
        <Grid item paddingBottom={2}>
          <Typography variant="body2">
            {labels.instructions}
          </Typography>
        </Grid>
        <Grid item width="100%" paddingBottom={2}>
          {/*{addLink ? (*/}
          <InputTextField
            required
            fullWidth
            label={labels.link}
            {...validation.link}
          />
          {/*{ ) : 
            <Button
              startIcon={<AddIcon />}
              onClick={() => setAddLink(!addLink)}
            >
          Lisää linkki myynti-ilmoitukseen
            </Button>
          )}(*/}
        </Grid>
        <Grid>
          <FormControlLabel required control={
            <Checkbox size="small" checked={isTermsChecked} onChange={handleCheckboxChange} />}
            label={
              <Typography variant="body2">
                {labels.terms} *
              </Typography>
            }
          />
        </Grid>
        <Grid item width="100%">
          <Button fullWidth type="submit" variant="contained" disabled={loading || !isTermsChecked}>
            {loading ? "Ladataan" : "Jatka"}
          </Button>
        </Grid>
      </VerticalContentGrid>
    </form>
  );
}

