import AddIcon from "@mui/icons-material/Add";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import VerticalContentGrid from "../common/VerticalContentGrid";

function ActionCard({ Icon, to, text }) {
  return (
    <Card sx={{ border: "1px solid #DDDDDD" }} elevation={2}>
      <CardActionArea component={Link} to={to}>
        <CardMedia sx={{ textAlign: "center" }}>
          <Icon sx={{ fontSize: 80, color: "primary.main", marginTop: 2 }} />
        </CardMedia>
        <CardContent sx={{ textAlign: "center" }}>
          <Typography variant="h6">{text}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default function Homepage() {
  const { user } = useUserContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <VerticalContentGrid rowSpacing={4}>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="body1" textAlign="center">
            Tervetuloa, {user?.name ?? ""}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" textAlign="center">
            Mitä tänään tehtäisiin?
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" textAlign="center" sx={{ paddingTop: 2 }}>
            Tietoomme on tullut huijausyrityksiä turvamaksut.fi:n nimissä, lue{" "}
            <Button
              onClick={() => navigate("/safety-advice")}
              sx={{
                padding: "0.1em 0",
                minWidth: "80px",
                verticalAlign: "middle",
                textTransform: "none",
                color: "blue",
                textDecoration: "underline",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
            >
              turvallisuusohjeet
            </Button>{" "}
            ennen käyttöä.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        item width="600px"
        wrap="wrap"
        spacing={2}
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <Grid item width="200px" height="200px">
          <ActionCard Icon={AddIcon} to="/create-transaction" text="Uusi kauppa" />
        </Grid>
        <Grid item width="200px" height="200px">
          <ActionCard Icon={AlarmOnIcon} to="/create-prepayment" text="Ennakkomaksu" />
        </Grid>
        <Grid item width="200px" height="200px">
          <ActionCard Icon={ReceiptLongIcon} to="/transactions" text="Kaupat" />
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        <Grid item>
          <ErrorOutlineIcon style={{ paddingRight: "10px" }} />
        </Grid>
        <Grid item width={!isMobile ? "400px" : "auto"}>
          <Typography
            variant="body2"
            textAlign={isMobile ? "center" : "inherit"}
          >
            Palvelu toimii tällä hetkellä seuraavien pankkien kanssa: OP,
            Nordea, Danske Bank, S-Pankki.
          </Typography>
        </Grid>
      </Grid>
    </VerticalContentGrid>
  );
}
