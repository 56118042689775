import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import InputTextField from "../common/form/InputTextField";
import { useUserService }from "../../hooks/useUserService.js";
import { useValidation } from "../../hooks/useValidation";
import { validation as signupValidation } from "../../shared/model/signup";
import LockResetIcon from "@mui/icons-material/LockReset";
import VerticalContentGrid from "../common/VerticalContentGrid";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { useUserContext } from "../../contexts/UserContext";

const labels = {
  email: "Sähköpostiosoite"
};

export default function ForgotPassword() {
  const { user } = useUserContext();
  const userService = useUserService();
  const apiErrorContext = useApiErrorContext();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const validation = {
    email: useValidation(signupValidation.email)
  };
  const emailAutofill = location.state?.email ?? "";

  const submit = async (event) => {
    event.preventDefault();

    const errors = Object.entries(validation)
      .map(([key, validation]) => ([ key, validation.validate() ]))
      .filter(([key, error]) => error !== null);

    if (errors.length > 0) {
      const error = errors.map(([key, error]) => `- ${labels[key] ?? "N/A"}: ${error}`).join("\n");
      apiErrorContext.addError(`Tiedoissa on virheitä:\n\n${error}`);
      return;
    }

    try {
      setLoading(true);
      const data = Object.entries(validation).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value.inputRef.current.value,
      }), {});
      await userService.sendPasswordResetEmail(data);
      setSuccess(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submit} style={{ height: "fit-content" }}>
      <VerticalContentGrid>
        <Grid item container justifyContent="center" alignItems="center" gap={2}>
          <LockResetIcon fontSize="large" />
          <Typography variant="h4">Salasanan palautus</Typography>
        </Grid>
        {success ? (
          <Grid item>
            <Typography variant="body1">
              Linkki salasanan palautukseen on lähetetty sähköpostiisi.
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item width="100%">
              <InputTextField
                label={labels.email}
                {...validation.email}
                autoFocus
                defaultValue={user?.email ?? emailAutofill}
                readOnly={!!user}
              />
            </Grid>
            <Grid item width="100%">
              <Button fullWidth variant="contained" type="submit" disabled={loading}>
                {!loading ? "Lähetä" : <CircularProgress size={24} color="inherit" />}
              </Button>
            </Grid>
          </>
        )}
        {!user && (
          <Grid item container justifyContent="end">
            <Button color="button" component={Link} to="/login">Takaisin kirjautumissivulle</Button>
          </Grid>
        )}
      </VerticalContentGrid>
    </form>
  );
}

