import React, { useState, useEffect, useContext, createContext, useCallback } from "react";
import { useUserService } from "../hooks/useUserService.js";
import useToken from "../hooks/useToken";
import { getJwtPayLoad } from "../util/jwt";
import User from "../shared/model/user";

const UserContext = createContext();

function UserProvider(props) {
  const { children } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [token] = useToken();
  const userService = useUserService();

  const loadUser = useCallback(async () => {
    const payload = getJwtPayLoad(token);
    const hasPayload = !!payload;
    const hasRole = [User.Role.User, User.Role.Admin].includes(payload?.role);
    const hasNotExpired = payload?.exp > Date.now() / 1000;

    if (hasPayload && hasRole && hasNotExpired) {
      try {
        setLoading(true);
        setError(false);
        setUser(await userService.profile());
      } catch (e) {
        setUser(null);
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError(false);
      setUser(null);
    }
  }, [token, userService]);

  useEffect(() => {
    if (token) {
      loadUser();
    }
  }, [token, loadUser]);

  return (
    <UserContext.Provider value={{ user, setUser, loading, error, loadUser }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined)
    throw new Error("UserContext must be used within it's provider");
  return context;
};

export default UserProvider;
