import Joi from "joi";
import { isValidIBAN } from "ibantools";
import validationConsts from "../validationConsts.js";
const {
  USER_EMAIL_MAX_LEN,
  USER_PASSWD_MAX_LEN,
  USER_PASSWD_MIN_LEN,
  USER_PHONE_MIN_LEN,
  USER_PHONE_MAX_LEN,
  USER_BANK_MAX_LEN,
  USER_ADDRESS_MAX_LEN,
  USER_CITY_MAX_LEN,
} = validationConsts;

export const validation = {
  email: Joi.string().max(USER_EMAIL_MAX_LEN).email({ tlds: false }).messages({
    "string.empty": "Pakollinen",
    "string.email": "Virheellinen sähköpostiosoite",
  }),
  password: Joi.string()
    .max(USER_PASSWD_MAX_LEN)
    .min(USER_PASSWD_MIN_LEN)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/)
    .messages({
      "string.empty": "Pakollinen",
      "string.pattern.base":
        "Salasanan tulee sisältää vähintään yksi pieni kirjain, yksi iso kirjain, yksi numero ja yksi erikoismerkki",
      "string.min": "Salasanan minimipituus 8 merkkiä",
    }),
  phoneNumber: Joi.string()
    .min(USER_PHONE_MIN_LEN)
    .max(USER_PHONE_MAX_LEN)
    .regex(/^[+].*$/)
    .messages({
      "string.empty": "Pakollinen",
      "string.pattern.base":
        "Syötä puhelinnumero kansainvälisessä muodossa +358501234567",
      "string.min": "Puhelinnumeron minimipituus 6 merkkiä",
      "string.max": "Puhelinnumeron maksimipituus 20 merkkiä",
    }),
  address: Joi.string().max(USER_ADDRESS_MAX_LEN).messages({
    "string.empty": "Pakollinen",
    "string.max": "Osoitteen maksimipituus on 200 merkkiä",
  }),
  zipcode: Joi.string()
    .regex(/^[0-9]{5}$/)
    .messages({
      "string.empty": "Pakollinen",
      "string.pattern.base": "Postinumerossa on oltava viisi numeroa",
    }),
  city: Joi.string().max(USER_CITY_MAX_LEN).messages({
    "string.empty": "Pakollinen",
    "string.max": "Kaupungin maksimipituus on 100 merkkiä",
  }),
  bankAccount: Joi.string()
    .custom((value, helpers) => {
      if (!isValidIBAN(value.replaceAll(/\s*/g, ""))) {
        throw new Error("Invalid IBAN");
      }
      return value;
    })
    .max(USER_BANK_MAX_LEN)
    .messages({
      "string.empty": "Pakollinen",
      "string.max": "Tilinumeron maksimipituus on 40 merkkiä",
      "any.custom": "Virheellinen IBAN",
    }),
  moneyOrigin: Joi.string()
    .messages({
      "string.empty": "Valitse vaihtoehto",
      "any.required": "Valitse vaihtoehto",
      "any.invalid": "Valitse vaihtoehto"
    }),
};
